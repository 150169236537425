/* prettier-ignore */
/**
 * NOTE:
 *
 * This file is automatically generated by Traduki 2.0.
 * DO NOT CHANGE IT BY HAND or your changes will be lost.
 */
//Polish (Poland)
export default {
  'proforma-jira-issue-forms.common.ui.footer.create-another-label': 'Utwórz następne zgłoszenie',
  'proforma-jira-issue-forms.common.ui.footer.create-button-label': 'Utwórz',
  'proforma-jira-issue-forms.common.ui.footer.failed-creating-flag-description': 'Spróbuj ponownie później.',
  'proforma-jira-issue-forms.common.ui.footer.failed-creating-flag-title': 'Nie można utworzyć zgłoszenia.',
  'proforma-jira-issue-forms.common.ui.footer.failed-creating-with-save-errors-flag-description': 'Popraw wszelkie nieprawidłowe odpowiedzi i sprawdź, czy wszystkie wymagane pola są wypełnione.',
  'proforma-jira-issue-forms.common.ui.footer.successfully-created-flag-copy-link-action': 'Kopiuj łącze',
  'proforma-jira-issue-forms.common.ui.footer.successfully-created-flag-title': 'Utworzono zgłoszenie "{issueKey}".',
  'proforma-jira-issue-forms.common.ui.footer.successfully-created-flag-view-issue-action': 'Wyświetl zgłoszenie',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-edit-form-link-label-for-project-admin': 'Edytuj formularz',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-is-conditional-action-for-project-admin': 'Edytuj formularz wniosku',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-is-conditional-description-for-non-project-admin': 'Poproś administratora o przeniesienie pola <strong>{formFieldName}</strong> poza sekcję warunkową lub usunięcie statusu „wymagane” z powiązanego z nim pola Jira <strong>{jiraFieldName}</strong> w formularzu wniosku <strong>{typeName}</strong>.',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-is-conditional-description-for-project-admin': 'Przenieś pole <strong>{formFieldName}</strong> poza sekcję warunkową lub usuń status „wymagane” z powiązanego z nim pola Jira <strong>{jiraFieldName}</strong> w formularzu wniosku <strong>{typeName}</strong>.',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-is-conditional-title': 'Pole „wymagane” w sekcji warunkowej',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-missing-description-for-non-project-admin': 'Poproś administratora o powiązanie pola w tym formularzu z polem Jira <strong>{jiraFieldName}</strong> w formularzu wniosku <strong>{typeName}</strong>.',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-missing-description-for-project-admin': 'Połącz pole w tym formularzu z polem Jira <strong>{jiraFieldName}</strong> w formularzu wniosku <strong>{typeName}</strong>.',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-missing-title': 'Pole „wymagane” nie jest powiązane',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-needs-to-be-mandatory-description-for-non-project-admin': 'Poproś administratora o oznaczenie pola <strong>{formFieldName}</strong> w tym formularzu jako „wymagane”, aby było zgodne z powiązanym polem Jira <strong>{jiraFieldName}</strong> w formularzu wniosku <strong>{typeName}</strong>.',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-needs-to-be-mandatory-description-for-project-admin': 'Oznacz pole <strong>{formFieldName}</strong> w tym formularzu jako &quot;wymagane&quot;, aby było zgodne z powiązanym polem Jira <strong>{jiraFieldName}</strong> w formularzu wniosku <strong>{typeName}</strong>.',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-needs-to-be-mandatory-title': 'Pole "wymagane" jest niezgodne',
  'proforma-jira-issue-forms.common.utils.process-form-load-error.form-loading-failed-flag-description': 'Odśwież stronę i spróbuj ponownie.',
  'proforma-jira-issue-forms.common.utils.process-form-load-error.form-loading-failed-flag-title': 'Nie można załadować formularza',
  'proforma-jira-issue-forms.controllers.form-store.object-search-error-description': 'Usuń atrybuty, które już nie istnieją w filtrze konfiguracji pola niestandardowego Zasobów, i upewnij się, że zapytanie AQL jest prawidłowe.',
  'proforma-jira-issue-forms.controllers.form-store.object-search-error-title': 'Nie można pobrać żadnych obiektów dla tego pola',
  'proforma-jira-issue-forms.ui.direct.embed.successfully-created-button-embed': 'Utwórz kolejne zgłoszenie',
  'proforma-jira-issue-forms.ui.direct.embed.successfully-created-description-with-link-embed': 'Wyświetl <a>issueKey</a>, aby zaktualizować to zgłoszenie lub nim zarządzać.',
  'proforma-jira-issue-forms.ui.direct.embed.successfully-created-title-embed': 'Utworzyliśmy Twoje zgłoszenie',
  'proforma-jira-issue-forms.ui.general.direct-link-section-message-embed': 'Twórz zgłoszenia za pomocą osadzonych formularzy, wklejając to łącze w dowolnej witrynie Atlassian. <a>Kopiuj łącze</a>',
  'proforma-jira-issue-forms.ui.general.header.error-loading-project-associated-forms-description': 'Odśwież stronę i spróbuj ponownie.',
  'proforma-jira-issue-forms.ui.general.header.error-loading-project-associated-forms-title': 'Wystąpił problem podczas ładowania Twoich formularzy',
  'proforma-jira-issue-forms.ui.general.header.error-loading-projects-description': 'Odśwież stronę i spróbuj ponownie.',
  'proforma-jira-issue-forms.ui.general.header.error-loading-projects-title': 'Wystąpił problem podczas ładowania Twoich projektów',
  'proforma-jira-issue-forms.ui.general.header.form-placeholder': 'Wybierz formularz',
  'proforma-jira-issue-forms.ui.general.header.issue-type-placeholder': 'Wybierz typ zgłoszenia',
  'proforma-jira-issue-forms.ui.general.header.page-description-embed': 'Twórz zgłoszenia za pomocą formularza lub korzystając bezpośrednio z łącza do formularza. <a>Dowiedz się więcej na temat tworzenia zgłoszeń za pomocą formularzy.</a>',
  'proforma-jira-issue-forms.ui.general.header.page-header': 'Utwórz zgłoszenie',
  'proforma-jira-issue-forms.ui.general.header.project-has-no-associated-forms-jsm': 'Ten projekt usługowy nie ma żadnych powiązanych formularzy.',
  'proforma-jira-issue-forms.ui.general.header.project-has-no-associated-forms-jsw': 'Ten projekt oprogramowania nie ma żadnych powiązanych formularzy.',
  'proforma-jira-issue-forms.ui.general.header.project-has-no-associated-forms-jwm': 'Ten projekt biznesowy nie ma żadnych powiązanych formularzy.',
  'proforma-jira-issue-forms.ui.general.header.project-placeholder': 'Wybierz projekt',
  'proforma-jira-issue-forms.ui.general.header.request-type-placeholder': 'Wybierz typ wniosku'
};