import { arDZ, cs, da, de, enAU, enGB, enUS, es, et, fi, fr, hu, is, it, ja, ko, nb, nl, pl, pt, ptBR, ro, ru, sk, sv, tr, zhCN, zhTW } from 'date-fns/locale';
import { DEFAULT_LOCALE } from '../../intl-provider/ProFormaIntlProvider';
export const locales = {
  arDZ,
  ar: arDZ,
  ar_DZ: arDZ,
  cs,
  cs_CZ: cs,
  'cs-CZ': cs,
  da,
  da_DK: da,
  'da-DK': da,
  de,
  de_DE: de,
  'de-DE': de,
  enUS,
  en_US: enUS,
  'en-US': enUS,
  enGB,
  en_UK: enGB,
  'en-UK': enGB,
  'en-GB': enGB,
  en_GB: enGB,
  enAU,
  en_AU: enAU,
  'en-AU': enAU,
  es,
  es_ES: es,
  'es-ES': es,
  et,
  et_EE: et,
  'et-EE': et,
  fi,
  fi_FI: fi,
  'fi-FI': fi,
  fr,
  fr_FR: fr,
  'fr-FR': fr,
  hu,
  hu_HU: hu,
  'hu-HU': hu,
  is,
  is_IS: is,
  'is-IS': is,
  it,
  it_IT: it,
  'it-IT': it,
  ja,
  ja_JP: ja,
  'ja-JP': ja,
  ko,
  ko_KR: ko,
  'ko-KR': ko,
  nb,
  nb_NO: nb,
  'nb-NO': nb,
  nl,
  nl_NL: nl,
  'nl-NL': nl,
  pl,
  pl_PL: pl,
  'pl-PL': pl,
  pt,
  'pt-PT': pt,
  pt_PT: pt,
  ptBR,
  'pt-BR': ptBR,
  pt_BR: ptBR,
  ro,
  ro_RO: ro,
  'ro-RO': ro,
  ru,
  ru_RU: ru,
  'ru-RU': ru,
  sk,
  sk_SK: sk,
  'sk-SK': sk,
  sv,
  sv_SE: sv,
  'sv-SE': sv,
  tr,
  'tr-TR': tr,
  tr_TR: tr,
  zhCN,
  'zh-CN': zhCN,
  zh_CN: zhCN,
  zhTW,
  'zh-TW': zhTW,
  zh_TW: zhTW
};
export const DEFAULT_WEEK_START_DAY = 0; // Sunday

export const getLocale = localeString => {
  const key = localeString;
  return locales[key] || locales[DEFAULT_LOCALE];
};