import { defineMessages } from 'react-intl-next';
export const messages = defineMessages({
  formLoadingFailedFlagDescription: {
    id: 'proforma-jira-issue-forms.common.utils.process-form-load-error.form-loading-failed-flag-description',
    defaultMessage: 'Refresh the page and try again.',
    description: 'Flag description displayed when loading the form failed in ProForma Issue Forms.'
  },
  formLoadingFailedFlagTitle: {
    id: 'proforma-jira-issue-forms.common.utils.process-form-load-error.form-loading-failed-flag-title',
    defaultMessage: 'We couldn’t load your form',
    description: 'Flag title displayed when loading the form failed in ProForma Issue Forms.'
  }
});