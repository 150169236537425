export const ApiFormChoicesResponseSchema = {
  $schema: 'http://json-schema.org/draft-04/schema#',
  description: 'Schema for the response of API formchoices.',
  $ref: '#/definitions/formchoices',
  definitions: {
    formchoices: {
      defaultProperties: [],
      properties: {
        fields: {
          type: 'object',
          patternProperties: {
            '^[a-zA-Z0-9{}/]+$': {
              items: {
                $ref: '#/definitions/jirafieldchoice'
              },
              type: 'array'
            }
          }
        },
        dataConnections: {
          type: 'object',
          patternProperties: {
            '^[a-zA-Z0-9{}/]+$': {
              items: {
                $ref: '#/definitions/jirafieldchoice'
              },
              type: 'array'
            }
          }
        }
      },
      required: ['dataConnections', 'fields'],
      type: 'object'
    },
    jirafieldchoice: {
      properties: {
        id: {
          type: 'string'
        },
        label: {
          type: 'string'
        }
      },
      required: ['id', 'label'],
      type: 'object'
    }
  }
};