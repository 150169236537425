import { ApiUtil } from '../../apis/live/ApiUtil';
import { setNoticeLocale } from '../show-notice';
import { LiveErrorUtils } from './LiveErrorUtils';
export function createErrorUtils(errorUtilsModuleName, environment, createAnalyticsEvent, showFlag = () => () => {}) {
  return new LiveErrorUtils(errorUtilsModuleName, showFlag, createAnalyticsEvent, environment);
}
export function createUtils(settings, errorUtilsModuleName, showFlag, createAnalyticsEvent, locale) {
  const errorUtils = new LiveErrorUtils(errorUtilsModuleName, showFlag, createAnalyticsEvent, settings.environment);
  setNoticeLocale(locale);
  const apiUtil = new ApiUtil(settings.urls.jira, settings.urls.api, errorUtils, createAnalyticsEvent);
  return {
    apiUtil,
    errorUtils
  };
}

// Same as createUtils above but we separate out the parts of the settings object that we want as separate parameters
export function createUtils2(jiraUrl, apiUrl, environment, errorUtilsModuleName, showFlag, createAnalyticsEvent, locale) {
  const errorUtils = new LiveErrorUtils(errorUtilsModuleName, showFlag, createAnalyticsEvent, environment);
  setNoticeLocale(locale);
  const apiUtil = new ApiUtil(jiraUrl, apiUrl, errorUtils, createAnalyticsEvent);
  return {
    apiUtil,
    errorUtils
  };
}