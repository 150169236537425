import { createContainer, createHook, createStore } from 'react-sweet-state';
const actions = {
  loadMediaApiUploadToken: projectId => ({
    getState,
    setState
  }, {
    apiUtil
  }) => {
    if (!getState().loading) {
      setState({
        data: undefined,
        error: undefined,
        loading: true
      });
      apiUtil.jiraGet(`/rest/api/2/attachment/upload/project/${projectId}/credentials`).then(response => {
        setState({
          data: response,
          loading: false
        });
      }).catch(apiError => {
        setState({
          error: apiError,
          loading: false
        });
      });
    }
  }
};
const Store = createStore({
  initialState: {
    data: undefined,
    error: undefined,
    loading: false
  },
  actions,
  name: 'AttachmentService'
});
export const AttachmentServiceContainer = createContainer(Store);
export const useAttachmentService = createHook(Store);