import { createContainer, createHook, createStore } from 'react-sweet-state';
const initialState = {
  data: undefined,
  error: undefined,
  loading: false
};
const actions = {
  createIssue: (formDetails, answers) => ({
    getState,
    setState
  }, {
    apiUtil
  }) => {
    if (!getState().loading) {
      var _formDetails$requestT;
      setState({
        data: undefined,
        error: undefined,
        loading: true
      });
      apiUtil.put('/api/2/createissue', {
        projectId: formDetails.projectId,
        templateFormId: formDetails.projectFormId,
        issueTypeId: formDetails.issueType.id,
        requestTypeId: (_formDetails$requestT = formDetails.requestType) === null || _formDetails$requestT === void 0 ? void 0 : _formDetails$requestT.id,
        answers
      }).then(response => {
        setState({
          data: response,
          loading: false
        });
      }).catch(apiError => {
        setState({
          error: apiError,
          loading: false
        });
      });
    }
  },
  reset: () => ({
    setState
  }) => {
    setState(initialState);
  }
};
const Store = createStore({
  initialState,
  actions,
  name: 'IssueCreateService'
});
export const IssueCreateServiceContainer = createContainer(Store);
export const useIssueCreateService = createHook(Store);