/* prettier-ignore */
/**
 * NOTE:
 *
 * This file is automatically generated by Traduki 2.0.
 * DO NOT CHANGE IT BY HAND or your changes will be lost.
 */
//Hungarian
export default {
  'proforma-jira-issue-forms.common.ui.footer.create-another-label': 'Másik ügy létrehozása',
  'proforma-jira-issue-forms.common.ui.footer.create-button-label': 'Létrehozás',
  'proforma-jira-issue-forms.common.ui.footer.failed-creating-flag-description': 'Próbálkozz újra később.',
  'proforma-jira-issue-forms.common.ui.footer.failed-creating-flag-title': 'Nem sikerült létrehozni az ügyet.',
  'proforma-jira-issue-forms.common.ui.footer.failed-creating-with-save-errors-flag-description': 'Oldd meg az érvénytelen válaszokat, és ellenőrizd, hogy az összes kötelező mező ki van-e töltve.',
  'proforma-jira-issue-forms.common.ui.footer.successfully-created-flag-copy-link-action': 'Link másolása',
  'proforma-jira-issue-forms.common.ui.footer.successfully-created-flag-title': 'Létrehoztad a(z) {issueKey} ügyet.',
  'proforma-jira-issue-forms.common.ui.footer.successfully-created-flag-view-issue-action': 'Ügy megtekintése',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-edit-form-link-label-for-project-admin': 'Űrlap szerkesztése',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-is-conditional-action-for-project-admin': 'Kéreleműrlap szerkesztése',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-is-conditional-description-for-non-project-admin': 'Kérd meg az adminisztrátorodat, hogy távolítsa el a(z) <strong>{formFieldName}</strong> mezőt a feltételeket tartalmazó szakaszból, vagy törölje a &quot;kötelező&quot; állapotot a hozzákapcsolt <strong>{jiraFieldName}</strong> Jira-mezőből a(z) <strong>{typeName}</strong> kéreleműrlapon.',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-is-conditional-description-for-project-admin': 'Távolítsd el a(z) <strong>{formFieldName}</strong> mezőt a feltételeket tartalmazó szakaszból, vagy töröld a &quot;kötelező&quot; állapotot a hozzákapcsolt <strong>{jiraFieldName}</strong> Jira-mezőből a(z) <strong>{typeName}</strong> kéreleműrlapon.',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-is-conditional-title': '„Kötelező“ mező a feltételeket tartalmazó szakaszban',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-missing-description-for-non-project-admin': 'Kérd meg az adminisztrátorodat, hogy kapcsoljon össze egy mezőt ezen az űrlapon a(z) <strong>{jiraFieldName}</strong> Jira-mezővel a(z) <strong>{typeName}</strong> kéreleműrlapon.',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-missing-description-for-project-admin': 'Kapcsolj össze egy mezőt ezen az űrlapon a(z) <strong>{jiraFieldName}</strong> Jira-mezővel a(z) <strong>{typeName}</strong> kéreleműrlapon.',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-missing-title': 'A „kötelező“ mező nincs hozzákapcsolva',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-needs-to-be-mandatory-description-for-non-project-admin': 'Kérd meg az adminisztrátorodat, hogy a(z) <strong>{formFieldName}</strong> mezőt jelölje meg „kötelezőként“ ezen az űrlapon, hogy egyezzen a hozzákapcsolt <strong>{jiraFieldName}</strong> Jira-mezővel a(z) <strong>{typeName}</strong> kéreleműrlapon.',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-needs-to-be-mandatory-description-for-project-admin': 'A(z) <strong>{formFieldName}</strong> mezőt jelöld meg szükségesként ezen az űrlapon, hogy egyezzen a hozzákapcsolt <strong>{jiraFieldName}</strong> Jira-mezővel a(z) <strong>{typeName}</strong> kéreleműrlapon.',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-needs-to-be-mandatory-title': 'A „Szükséges“ mező nem egyezik',
  'proforma-jira-issue-forms.common.utils.process-form-load-error.form-loading-failed-flag-description': 'Frissítsd az oldalt, majd próbálkozz újra.',
  'proforma-jira-issue-forms.common.utils.process-form-load-error.form-loading-failed-flag-title': 'Nem sikerült betölteni az űrlapot',
  'proforma-jira-issue-forms.controllers.form-store.object-search-error-description': 'Távolítsd el az Eszközök-egyéni mező konfigurációs szűrőjében már nem létező attribútumokat, és gondoskodj róla, hogy az AQL-lekérdezés érvényes legyen.',
  'proforma-jira-issue-forms.controllers.form-store.object-search-error-title': 'Nem sikerült lekérni egyetlen objektumot sem ehhez a mezőhöz',
  'proforma-jira-issue-forms.ui.direct.embed.successfully-created-button-embed': 'Másik ügy létrehozása',
  'proforma-jira-issue-forms.ui.direct.embed.successfully-created-description-with-link-embed': 'Az ügy frissítéséhez vagy kezeléséhez tekintsd meg a következő ügykulcsot: <a>issueKey</a>.',
  'proforma-jira-issue-forms.ui.direct.embed.successfully-created-title-embed': 'Létrehoztuk az ügyet',
  'proforma-jira-issue-forms.ui.general.direct-link-section-message-embed': 'Illeszd be ezt a linket bármilyen Atlassian-webhelyre, és hozz létre ügyeket a beágyazott űrlapokkal. <a>Link másolása</a>',
  'proforma-jira-issue-forms.ui.general.header.error-loading-project-associated-forms-description': 'Frissítsd az oldalt, majd próbálkozz újra.',
  'proforma-jira-issue-forms.ui.general.header.error-loading-project-associated-forms-title': 'Probléma merült fel az űrlapok betöltése során',
  'proforma-jira-issue-forms.ui.general.header.error-loading-projects-description': 'Frissítsd az oldalt, majd próbálkozz újra.',
  'proforma-jira-issue-forms.ui.general.header.error-loading-projects-title': 'Probléma merült fel a projektek betöltése során',
  'proforma-jira-issue-forms.ui.general.header.form-placeholder': 'Válassz ki egy űrlapot',
  'proforma-jira-issue-forms.ui.general.header.issue-type-placeholder': 'Válassz ki egy ügytípust',
  'proforma-jira-issue-forms.ui.general.header.page-description-embed': 'Hozz létre ügyeket egy űrlap vagy egy közvetlenül az űrlapra mutató link segítségével. <a>További információ az ügyek űrlapok segítségével történő létrehozásáról.</a>',
  'proforma-jira-issue-forms.ui.general.header.page-header': 'Ügy létrehozása',
  'proforma-jira-issue-forms.ui.general.header.project-has-no-associated-forms-jsm': 'Ehhez az ügyfélszolgálati projekthez nincs társítva űrlap.',
  'proforma-jira-issue-forms.ui.general.header.project-has-no-associated-forms-jsw': 'Ehhez a szoftverprojekthez nincs társítva űrlap.',
  'proforma-jira-issue-forms.ui.general.header.project-has-no-associated-forms-jwm': 'Ehhez az üzleti projekthez nincs társítva űrlap.',
  'proforma-jira-issue-forms.ui.general.header.project-placeholder': 'Válassz ki egy projektet',
  'proforma-jira-issue-forms.ui.general.header.request-type-placeholder': 'Válassz ki egy kérelemtípust'
};