/* prettier-ignore */
/**
 * NOTE:
 *
 * This file is automatically generated by Traduki 2.0.
 * DO NOT CHANGE IT BY HAND or your changes will be lost.
 */
//Thai (Thailand)
export default {
  'proforma-jira-issue-forms.common.ui.footer.create-another-label': 'จัดทำปัญหาอีกหนึ่งรายการ',
  'proforma-jira-issue-forms.common.ui.footer.create-button-label': 'จัดทำ',
  'proforma-jira-issue-forms.common.ui.footer.failed-creating-flag-description': 'โปรดลองอีกครั้งในภายหลัง',
  'proforma-jira-issue-forms.common.ui.footer.failed-creating-flag-title': 'เราไม่สามารถจัดทำปัญหาของคุณได้',
  'proforma-jira-issue-forms.common.ui.footer.failed-creating-with-save-errors-flag-description': 'แก้ไขคำตอบที่ไม่ถูกต้องและตรวจสอบว่าได้กรอกช่องที่จำเป็นทั้งหมดครบถ้วนแล้ว',
  'proforma-jira-issue-forms.common.ui.footer.successfully-created-flag-copy-link-action': 'คัดลอกลิงก์',
  'proforma-jira-issue-forms.common.ui.footer.successfully-created-flag-title': 'คุณได้จัดทำปัญหา "{issueKey}" แล้ว',
  'proforma-jira-issue-forms.common.ui.footer.successfully-created-flag-view-issue-action': 'ดูปัญหา',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-edit-form-link-label-for-project-admin': 'แก้ไขแบบฟอร์ม',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-is-conditional-action-for-project-admin': 'แก้ไขแบบฟอร์มคำขอ',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-is-conditional-description-for-non-project-admin': 'ขอให้ผู้ดูแลของคุณย้ายช่อง <strong>{formFieldName}</strong> ออกจากส่วนที่มีเงื่อนไข หรือลบสถานะ &quot;จำเป็นต้องระบุ&quot; ออกจากช่อง Jira <strong>{jiraFieldName}</strong> ที่เชื่อมโยงอยู่บนแบบฟอร์มคำขอ <strong>{typeName}</strong>',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-is-conditional-description-for-project-admin': 'ย้ายช่อง <strong>{formFieldName}</strong> ออกจากส่วนที่มีเงื่อนไข หรือลบสถานะ &quot;จำเป็นต้องระบุ&quot; ออกจากช่อง Jira <strong>{jiraFieldName}</strong> ที่เชื่อมโยงอยู่บนแบบฟอร์มคำขอ <strong>{typeName}</strong>',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-is-conditional-title': 'ช่อง "จำเป็นต้องระบุ" ในส่วนที่มีเงื่อนไข',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-missing-description-for-non-project-admin': 'ขอให้ผู้ดูแลของคุณเชื่อมโยงช่องในแบบฟอร์มนี้ด้วยช่อง Jira <strong>{jiraFieldName}</strong> ในแบบฟอร์มคำขอ <strong>{typeName}</strong>',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-missing-description-for-project-admin': 'เชื่อมโยงฟิลด์ในแบบฟอร์มนี้ด้วย <strong>{jiraFieldName}</strong>ฟิลด์ Jira ในแบบฟอร์มคำขอ <strong>{typeName}</strong>',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-missing-title': 'ไม่ได้เชื่อมโยงช่อง "จำเป็นต้องระบุ"',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-needs-to-be-mandatory-description-for-non-project-admin': 'ขอให้ผู้ดูแลของคุณทำเครื่องหมายช่อง <strong>{formFieldName}</strong> ในแบบฟอร์มนี้เป็น &quot;จำเป็นต้องระบุ&quot; เพื่อให้ตรงกับช่อง Jira <strong>{jiraFieldName}</strong> ที่เชื่อมโยงในแบบฟอร์มคำขอ <strong>{typeName}</strong>',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-needs-to-be-mandatory-description-for-project-admin': 'ทำเครื่องหมายช่อง <strong>{formFieldName}</strong> ในแบบฟอร์มนี้เป็น ‘จำเป็นต้องระบุ’ เพื่อให้ตรงกับช่อง Jira <strong>{jiraFieldName}</strong> ที่เชื่อมโยงในแบบฟอร์มคำขอ <strong>{typeName}</strong>',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-needs-to-be-mandatory-title': "ช่อง 'ต้องระบุ' ไม่ตรงกัน",
  'proforma-jira-issue-forms.common.utils.process-form-load-error.form-loading-failed-flag-description': 'รีเฟรชหน้านี้ แล้วลองอีกครั้ง',
  'proforma-jira-issue-forms.common.utils.process-form-load-error.form-loading-failed-flag-title': 'เราไม่สามารถโหลดแบบฟอร์มของคุณได้',
  'proforma-jira-issue-forms.controllers.form-store.object-search-error-description': 'ลบคุณลักษณะที่ไม่มีอยู่ในตัวกรองการกำหนดค่าของช่องที่กำหนดเองสำหรับ Assets ออกและตรวจสอบให้แน่ใจว่าคำถาม AQL นั้นถูกต้อง',
  'proforma-jira-issue-forms.controllers.form-store.object-search-error-title': 'เราไม่สามารถดึงหัวเรื่องใด ๆ สำหรับช่องนี้',
  'proforma-jira-issue-forms.ui.direct.embed.successfully-created-button-embed': 'สร้างปัญหาอีกหนึ่งรายการ',
  'proforma-jira-issue-forms.ui.direct.embed.successfully-created-description-with-link-embed': 'ดู <a>issueKey</a> เพื่ออัปเดต หรือจัดการปัญหานี้',
  'proforma-jira-issue-forms.ui.direct.embed.successfully-created-title-embed': 'เราได้สร้างปัญหาของคุณแล้ว',
  'proforma-jira-issue-forms.ui.general.direct-link-section-message-embed': 'สร้างปัญหาต่าง ๆ โดยใช้แบบฟอร์มที่ฝังไว้ภายในด้วยการวางลิงก์นี้บนไซต์ Atlassian ใดก็ได้ <a>คัดลอกลิงก์</a>',
  'proforma-jira-issue-forms.ui.general.header.error-loading-project-associated-forms-description': 'รีเฟรชหน้านี้ แล้วลองอีกครั้ง',
  'proforma-jira-issue-forms.ui.general.header.error-loading-project-associated-forms-title': 'เรากำลังมีปัญหาในการโหลดแบบฟอร์มของคุณ',
  'proforma-jira-issue-forms.ui.general.header.error-loading-projects-description': 'รีเฟรชหน้านี้ แล้วลองอีกครั้ง',
  'proforma-jira-issue-forms.ui.general.header.error-loading-projects-title': 'เรากำลังมีปัญหาในการโหลดโปรเจกต์ของคุณ',
  'proforma-jira-issue-forms.ui.general.header.form-placeholder': 'เลือกแบบฟอร์ม',
  'proforma-jira-issue-forms.ui.general.header.issue-type-placeholder': 'เลือกประเภทปัญหา',
  'proforma-jira-issue-forms.ui.general.header.page-description-embed': 'สร้างปัญหาต่าง ๆ โดยใช้แบบฟอร์ม หรือสร้างจากลิงก์ไปยังแบบฟอร์มโดยตรง <a>เรียนรู้เพิ่มเติมเกี่ยวกับการใช้แบบฟอร์มเพื่อสร้างปัญหา</a>',
  'proforma-jira-issue-forms.ui.general.header.page-header': 'จัดทำปัญหา',
  'proforma-jira-issue-forms.ui.general.header.project-has-no-associated-forms-jsm': 'โปรเจกต์บริการนี้ไม่มีแบบฟอร์มใด ๆ ที่เกี่ยวข้อง',
  'proforma-jira-issue-forms.ui.general.header.project-has-no-associated-forms-jsw': 'โปรเจกต์ซอฟต์แวร์นี้ไม่มีแบบฟอร์มใด ๆ ที่เกี่ยวข้อง',
  'proforma-jira-issue-forms.ui.general.header.project-has-no-associated-forms-jwm': 'โปรเจกต์ธุรกิจนี้ไม่มีแบบฟอร์มใด ๆ ที่เกี่ยวข้อง',
  'proforma-jira-issue-forms.ui.general.header.project-placeholder': 'เลือกโปรเจกต์',
  'proforma-jira-issue-forms.ui.general.header.request-type-placeholder': 'เลือกประเภทคำขอ'
};