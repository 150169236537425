/* prettier-ignore */
/**
 * NOTE:
 *
 * This file is automatically generated by Traduki 2.0.
 * DO NOT CHANGE IT BY HAND or your changes will be lost.
 */
//Russian
export default {
  'proforma-jira-issue-forms.common.ui.footer.create-another-label': 'Создать еще одну задачу',
  'proforma-jira-issue-forms.common.ui.footer.create-button-label': 'Создать',
  'proforma-jira-issue-forms.common.ui.footer.failed-creating-flag-description': 'Повторите попытку позже.',
  'proforma-jira-issue-forms.common.ui.footer.failed-creating-flag-title': 'Не удалось создать задачу.',
  'proforma-jira-issue-forms.common.ui.footer.failed-creating-with-save-errors-flag-description': 'Исправьте все недопустимые ответы и убедитесь, что все обязательные поля заполнены.',
  'proforma-jira-issue-forms.common.ui.footer.successfully-created-flag-copy-link-action': 'Копировать ссылку',
  'proforma-jira-issue-forms.common.ui.footer.successfully-created-flag-title': 'Создана задача «{issueKey}».',
  'proforma-jira-issue-forms.common.ui.footer.successfully-created-flag-view-issue-action': 'Просмотреть задачу',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-edit-form-link-label-for-project-admin': 'Редактировать форму',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-is-conditional-action-for-project-admin': 'Редактировать форму запроса',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-is-conditional-description-for-non-project-admin': 'Попросите администратора перенести поле <strong>{formFieldName}</strong> из условного раздела или убрать статус «обязательно» у связанного поля Jira <strong>{jiraFieldName}</strong> в форме запроса <strong>{typeName}</strong>.',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-is-conditional-description-for-project-admin': 'Перенесите поле <strong>{formFieldName}</strong> из условного раздела или уберите статус «обязательно» у связанного поля Jira <strong>{jiraFieldName}</strong> в форме запроса <strong>{typeName}</strong>.',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-is-conditional-title': '«Обязательное» поле в условном разделе',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-missing-description-for-non-project-admin': 'Попросите администратора связать поле в этой форме с полем Jira <strong>{jiraFieldName}</strong> в форме запроса <strong>{typeName}</strong>.',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-missing-description-for-project-admin': 'Свяжите поле в этой форме с полем Jira <strong>{jiraFieldName}</strong> в форме запроса <strong>{typeName}</strong>.',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-missing-title': '«Обязательное» поле не связано',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-needs-to-be-mandatory-description-for-non-project-admin': 'Попросите администратора отметить поле <strong>{formFieldName}</strong> в этой форме как «обязательное», чтобы оно соответствовало связанному полю Jira <strong>{jiraFieldName}</strong> в форме запроса <strong>{typeName}</strong>.',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-needs-to-be-mandatory-description-for-project-admin': 'Отметьте поле <strong>{formFieldName}</strong> в этой форме как обязательное, чтобы оно соответствовало связанному полю Jira <strong>{jiraFieldName}</strong> в форме запроса <strong>{typeName}</strong>.',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-needs-to-be-mandatory-title': '«Обязательное» поле не соответствует',
  'proforma-jira-issue-forms.common.utils.process-form-load-error.form-loading-failed-flag-description': 'Обновите страницу и повторите попытку.',
  'proforma-jira-issue-forms.common.utils.process-form-load-error.form-loading-failed-flag-title': 'Не удалось загрузить форму',
  'proforma-jira-issue-forms.controllers.form-store.object-search-error-description': 'Удалите атрибуты, которые больше не существуют, из фильтра конфигурации пользовательского поля «Активы» и убедитесь, что AQL-запрос является допустимым.',
  'proforma-jira-issue-forms.controllers.form-store.object-search-error-title': 'Не удалось получить объекты для этого поля',
  'proforma-jira-issue-forms.ui.direct.embed.successfully-created-button-embed': 'Создать еще одну задачу',
  'proforma-jira-issue-forms.ui.direct.embed.successfully-created-description-with-link-embed': 'Просмотрите задачу <a>issueKey</a>, чтобы обновить ее или изменить настройки.',
  'proforma-jira-issue-forms.ui.direct.embed.successfully-created-title-embed': 'Задача создана',
  'proforma-jira-issue-forms.ui.general.direct-link-section-message-embed': 'Создавайте задачи с помощью встроенных форм — просто вставьте эту ссылку на любой сайт Atlassian. <a>Копировать ссылку</a>',
  'proforma-jira-issue-forms.ui.general.header.error-loading-project-associated-forms-description': 'Обновите страницу и повторите попытку.',
  'proforma-jira-issue-forms.ui.general.header.error-loading-project-associated-forms-title': 'При загрузке форм возникла проблема',
  'proforma-jira-issue-forms.ui.general.header.error-loading-projects-description': 'Обновите страницу и повторите попытку.',
  'proforma-jira-issue-forms.ui.general.header.error-loading-projects-title': 'При загрузке проектов возникла проблема',
  'proforma-jira-issue-forms.ui.general.header.form-placeholder': 'Выберите форму',
  'proforma-jira-issue-forms.ui.general.header.issue-type-placeholder': 'Выберите тип задачи',
  'proforma-jira-issue-forms.ui.general.header.page-description-embed': 'Создавайте задачи с помощью формы или прямой ссылки на форму. <a>Подробнее о создании задач с помощью форм.</a>',
  'proforma-jira-issue-forms.ui.general.header.page-header': 'Создание задачи',
  'proforma-jira-issue-forms.ui.general.header.project-has-no-associated-forms-jsm': 'У этого проекта службы поддержки нет связанных форм.',
  'proforma-jira-issue-forms.ui.general.header.project-has-no-associated-forms-jsw': 'У этого проекта по разработке ПО нет связанных форм.',
  'proforma-jira-issue-forms.ui.general.header.project-has-no-associated-forms-jwm': 'У этого бизнес-проекта нет связанных форм.',
  'proforma-jira-issue-forms.ui.general.header.project-placeholder': 'Выберите проект',
  'proforma-jira-issue-forms.ui.general.header.request-type-placeholder': 'Выберите тип запроса'
};