import { useEffect } from 'react';

// @ts-expect-error - TS7016 - Could not find a declaration file for module 'jwt-decode'. '/buildeng/bamboo-agent-home/xml-data/build-dir/JF-TSMIG123-APPLY/node_modules/jwt-decode/lib/index.js' implicitly has an 'any' type.
import jwtDecode from 'jwt-decode';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { fireUIAnalytics } from '@atlassian/analytics-bridge';
import { transformReadAttachmentsToken } from '@atlassian/jsm-attachment';
import { Action, ActionSubject } from '@atlassian/proforma-utils/analytics';
import { useMediaContext } from '../../context';
export const useEmitAttachmentMetrics = (formStore, view) => {
  const {
    mediaApiReadToken,
    mediaUploadAndViewContexts,
    projectId,
    portalId,
    issueKey
  } = useMediaContext();
  const {
    createAnalyticsEvent
  } = useAnalyticsEvents();
  useEffect(() => {
    if (view && formStore.numberOfAttachment > 0) {
      try {
        var _mediaUploadAndViewCo;
        const viewContextToken = mediaUploadAndViewContexts ? (_mediaUploadAndViewCo = mediaUploadAndViewContexts.viewContext) === null || _mediaUploadAndViewCo === void 0 ? void 0 : _mediaUploadAndViewCo.token : mediaApiReadToken ? transformReadAttachmentsToken(mediaApiReadToken).token : null;
        let viewableIds = new Set();
        if (viewContextToken) {
          // when there are no attachments, the token will be undefined and so do not decode
          const {
            access
          } = jwtDecode(viewContextToken);
          const viewableFiles = Object.entries(access).filter(([_, value]) => value.includes('read'));
          viewableIds = new Set(viewableFiles.flatMap(([key, _]) => key.split(':')[3]));
        }
        const brokenFiles = formStore.questions.filter(questionStore => questionStore.formQuestion.type === 'at').flatMap(questionStore => {
          const attachments = questionStore.currentAnswer;
          return attachments.filter(attachment => !viewableIds.has(attachment.id));
        });
        const attachmentIdCreationDtStr = attachments => {
          return attachments.map(attachment => `${attachment.id}: ${attachment.creationDate}`).join(', ');
        };
        if (brokenFiles.length > 0) {
          fireUIAnalytics(createAnalyticsEvent({
            actionSubject: ActionSubject.ATTACHMENT_VIEWER_CARD,
            action: Action.FAILED
          }), `${ActionSubject.ATTACHMENT_VIEWER_CARD} ${Action.FAILED}`, projectId ? {
            projectId,
            issueKey,
            attachmentCreationDate: attachmentIdCreationDtStr(brokenFiles)
          } : {
            portalId,
            issueKey,
            attachmentCreationDate: attachmentIdCreationDtStr(brokenFiles)
          });
        }
      } catch (error) {
        fireUIAnalytics(createAnalyticsEvent({
          actionSubject: ActionSubject.ATTACHMENT_VIEWER_CARD,
          action: Action.FAILED
        }), `${ActionSubject.ATTACHMENT_VIEWER_CARD} ${Action.FAILED}`, projectId ? {
          projectId,
          issueKey,
          error: JSON.stringify(error)
        } : {
          portalId,
          issueKey,
          error: JSON.stringify(error)
        });
      }
    }
    // disabling exhaustive-deps as the metric is to track failed to load attachments which only occurs on first render
    // and we do not want duplicate events on re-renders
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createAnalyticsEvent, issueKey, portalId, projectId]);
};