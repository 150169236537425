const productSegmentsFn = (projectType, isSimplifiedProject) => {
  return projectType === 'business' ? 'core' : projectType === 'service_desk' ? 'servicedesk' : /* projectType === 'software' && */isSimplifiedProject ? 'software' : 'software/c';
};
export const formBuilderUrl = (projectKey, projectType, isSimplifiedProject, templateFormId) => {
  const productSegments = productSegmentsFn(projectType, isSimplifiedProject);
  return `/jira/${productSegments}/projects/${projectKey}/settings/forms/form/${templateFormId}/edit`;
};
export const issueTypeConfigurationUrl = (projectKey, projectType, isSimplifiedProject, issueTypeId) => {
  if (!isSimplifiedProject) {
    return `/plugins/servlet/project-config/${projectKey}/issuetypes/${issueTypeId}`;
  }
  const productSegments = productSegmentsFn(projectType, isSimplifiedProject);
  return `/jira/${productSegments}/projects/${projectKey}/settings/issuetypes/${issueTypeId}`;
};
export const requestTypeConfigurationUrl = (projectKey, projectType, isSimplifiedProject, requestTypeId) => {
  if (projectType !== 'service_desk' || isSimplifiedProject) {
    throw Error(`Request type configuration does not exist for '${projectType}' ${isSimplifiedProject ? 'simplified ' : ''}projects.`);
  }
  return `/jira/servicedesk/projects/${projectKey}/settings/request-types/request-type/${requestTypeId}`;
};