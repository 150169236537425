import { FormQuestionType } from '../../models/Form';
import { toAttachmentQuestionAnswer, toChoiceQuestionAnswer, toDateTimeQuestionAnswer, toRichTextQuestionAnswer, toTextQuestionAnswer, toUserPickerQuestionAnswer } from '../../stores/QuestionStore';
export const convertAnswers = questions => {
  const answersMap = {};
  questions.forEach(question => {
    answersMap[question.id.toString()] = getFormAnswer(question);
  });
  return answersMap;
};
export const getAnswerValue = answer => {
  return answer === undefined ? null : answer;
};
function getFormAnswer(question) {
  var _toDateTimeQuestionAn, _toDateTimeQuestionAn2, _toChoiceQuestionAnsw, _toChoiceQuestionAnsw2;
  switch (question.formQuestion.type) {
    case FormQuestionType.TextShort:
    case FormQuestionType.TextLong:
    case FormQuestionType.TextParagraph:
    case FormQuestionType.TextEmail:
    case FormQuestionType.TextUrl:
    case FormQuestionType.Number:
      return {
        text: getAnswerValue(toTextQuestionAnswer(question.currentAnswer))
      };
    case FormQuestionType.RichTextParagraph:
      return {
        adf: toRichTextQuestionAnswer(question.currentAnswer) || null
      };
    case FormQuestionType.DateTime:
      return {
        date: ((_toDateTimeQuestionAn = toDateTimeQuestionAnswer(question.currentAnswer)) === null || _toDateTimeQuestionAn === void 0 ? void 0 : _toDateTimeQuestionAn.date) || null,
        time: ((_toDateTimeQuestionAn2 = toDateTimeQuestionAnswer(question.currentAnswer)) === null || _toDateTimeQuestionAn2 === void 0 ? void 0 : _toDateTimeQuestionAn2.time) || null
      };
    case FormQuestionType.Date:
      return {
        date: toTextQuestionAnswer(question.currentAnswer) || null
      };
    case FormQuestionType.Time:
      return {
        time: toTextQuestionAnswer(question.currentAnswer) || null
      };
    case FormQuestionType.ChoiceCascading:
    case FormQuestionType.ChoiceSingle:
    case FormQuestionType.ChoiceMultiple:
    case FormQuestionType.ChoiceDropdown:
    case FormQuestionType.ChoiceDropdownMultiple:
    case FormQuestionType.CmdbObject:
      return {
        text: (_toChoiceQuestionAnsw = toChoiceQuestionAnswer(question.currentAnswer)) === null || _toChoiceQuestionAnsw === void 0 ? void 0 : _toChoiceQuestionAnsw.text,
        choices: (_toChoiceQuestionAnsw2 = toChoiceQuestionAnswer(question.currentAnswer)) === null || _toChoiceQuestionAnsw2 === void 0 ? void 0 : _toChoiceQuestionAnsw2.choices
      };
    case FormQuestionType.UserSingle:
    case FormQuestionType.UserMultiple:
      return {
        users: toUserPickerQuestionAnswer(question.currentAnswer)
      };
    case FormQuestionType.Attachment:
      return {
        files: toAttachmentQuestionAnswer(question.currentAnswer)
      };
    default:
      return {
        text: toTextQuestionAnswer(question.currentAnswer)
      };
  }
}