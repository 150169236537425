/* TODO: Remove this eslint disable when prettier is fixed to prevent it adding extra newlines */
import { defineMessages } from 'react-intl-next';
export const messages = defineMessages({
  objectSearchErrorTitle: {
    id: 'proforma-jira-issue-forms.controllers.form-store.object-search-error-title',
    // eslint-disable-next-line quotes
    defaultMessage: "We couldn't fetch any objects for this field",
    description: 'Error title informing the user that there was an error while fetching CMDB objects in jira issue view'
  },
  objectSearchErrorDescription: {
    id: 'proforma-jira-issue-forms.controllers.form-store.object-search-error-description',
    defaultMessage: 'Remove attributes that no longer exist in the Assets Custom field configuration filter, and ensure the AQL query is valid.',
    description: 'Error message informing user the potential issues of failing CMDB object search in jira issue view'
  }
});