import type { Environment } from '@atlassian/jira-shared-types/src/tenant-context.tsx';
import { Environment as ProformaEnvironment } from '@atlassian/proforma-common-core/jira-common-models';

export const toProformaEnvironment = (environment: Environment) => {
	switch (environment) {
		case 'dev':
			return ProformaEnvironment.DEVELOPMENT;
		case 'staging':
			return ProformaEnvironment.STAGING;
		case 'prod':
			return ProformaEnvironment.PRODUCTION;
		case 'local':
		default:
			return ProformaEnvironment.LOCAL;
	}
};
