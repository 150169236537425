// Ideally, `SelectOption` should extend `OptionType` provided by `@atlaskit/select`, but
// `OptionType` has a property `[key: string]: any;`, which opens the door for broken code - using
// properties that are not defined.

export const toSelectOption = choice => {
  var _choice$children;
  return {
    value: choice.id,
    label: choice.label,
    children: (_choice$children = choice.children) === null || _choice$children === void 0 ? void 0 : _choice$children.map(toSelectOption)
  };
};