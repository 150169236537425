import { FormQuestionType, FormStatus } from '../../../form-system/models/Form';
import { validate } from '../../models/validators';
export const loadChoicesFn = (getFormChoices, errorUtils, loadFormChoicesForASubmittedForm = true) => {
  return formStore => {
    return (questionId, query) => {
      if (!loadFormChoicesForASubmittedForm && formStore.status !== FormStatus.Open) {
        // When a form is submitted, the choices are stored within the questions.
        return Promise.resolve();
      }
      // map jiraField to QuestionStore[] for easy lookup of all questions which are linked to a Jira field.
      const jiraFieldQuestionMap = new Map();
      // map jiraField to QuestionStore[] for easy lookup of all questions which are linked to a data connection.
      const dataConnectionQuestionMap = new Map();
      // Allocate out each linked choice question to either the appropriate map.
      formStore.questions.forEach(question => {
        if (!questionId || questionId === question.id) {
          if (question.formQuestion.jiraField && isChoiceType(question.formQuestion.type)) {
            let questionStores;
            if (jiraFieldQuestionMap.has(question.formQuestion.jiraField)) {
              questionStores = jiraFieldQuestionMap.get(question.formQuestion.jiraField);
            } else {
              questionStores = [];
              jiraFieldQuestionMap.set(question.formQuestion.jiraField, questionStores);
            }
            questionStores.push(question);
          }
          if (question.formQuestion.dcId) {
            let questionStores = dataConnectionQuestionMap.get(question.formQuestion.dcId);
            if (questionStores === undefined) {
              questionStores = [];
              dataConnectionQuestionMap.set(question.formQuestion.dcId, questionStores);
            }
            questionStores.push(question);
          }
        }
      });
      const lookups = [];

      // Retrieve formStore choices from the backend if there are any linked choice fields handled by ProForma
      if (jiraFieldQuestionMap.size > 0 || dataConnectionQuestionMap.size > 0) {
        lookups.push(getFormChoices(formStore.templateFormId, formStore.formId).then(response => {
          const validationResult = validate('ApiFormChoicesResponseSchema', response);
          if (!validationResult.isValid) {
            errorUtils.reportError(new Error('Response type mismatch'), {
              id: 'ApiFormChoicesResponseSchema',
              attributes: {
                errors: JSON.stringify(validationResult.errors)
              }
            });
            return;
          }
          const {
            fields,
            dataConnections
          } = response;
          jiraFieldQuestionMap.forEach((questionStores, jiraField) => {
            if (fields[jiraField]) {
              questionStores.forEach(questionStore => questionStore.setChoices(fields[jiraField]));
            }
          });
          dataConnectionQuestionMap.forEach((questionStores, dcId) => {
            if (dataConnections[dcId]) {
              questionStores.forEach(questionStore => {
                questionStore.setChoices(dataConnections[dcId]);
              });
            }
          });
        }));
      }
      return Promise.all(lookups).then(_ => {});
    };
  };
};
function isChoiceType(questionType) {
  return questionType === FormQuestionType.ChoiceCascading || questionType === FormQuestionType.ChoiceDropdown || questionType === FormQuestionType.ChoiceSingle || questionType === FormQuestionType.ChoiceMultiple || questionType === FormQuestionType.ChoiceDropdownMultiple;
}