import { createContainer, createHook, createStore } from 'react-sweet-state';
const actions = {
  load: (projectId, jiraField, query) => ({
    getState,
    setState
  }, {
    apiUtil
  }) => {
    if (!getState().loading) {
      setState({
        data: undefined,
        error: undefined,
        loading: true
      });
      (jiraField === 'assignee' ? apiUtil.jiraGet(`/rest/api/3/user/assignable/search?project=${projectId}&query=${encodeURIComponent(query)}`).then(data => data.map(user => {
        var _user$displayName;
        return {
          value: user.accountId,
          label: (_user$displayName = user.displayName) !== null && _user$displayName !== void 0 ? _user$displayName : ''
        };
      })) : apiUtil.jiraGet(`/rest/api/3/user/picker?query=${encodeURIComponent(query)}`).then(data => data.users.map(user => {
        var _user$displayName2;
        return {
          value: user.accountId,
          label: (_user$displayName2 = user.displayName) !== null && _user$displayName2 !== void 0 ? _user$displayName2 : ''
        };
      }))).then(users => {
        setState({
          data: users,
          loading: false
        });
      }).catch(apiError => {
        setState({
          error: apiError,
          loading: false
        });
      });
    }
  },
  loadWithFieldConfig: (jiraField, fieldConfigId, projectId, query) => ({
    getState,
    setState
  }, {
    apiUtil
  }) => {
    if (!getState().loading) {
      setState({
        data: undefined,
        error: undefined,
        loading: true
      });
      let usersP;
      if (jiraField === 'assignee') {
        const url = `/rest/api/3/user/assignable/search?project=${projectId}&query=${encodeURIComponent(query)}`;
        usersP = getAssignableUsers(apiUtil, url);
      } else if (jiraField && fieldConfigId) {
        const url = `/rest/api/1.0/users/picker?fieldName=${jiraField}&fieldConfigId=${fieldConfigId}&projectId=${projectId}&showAvatar=true&&query=${encodeURIComponent(query)}`;
        usersP = getFoundUsers(apiUtil, url);
      } else {
        const url = `/rest/api/3/user/picker?query=${encodeURIComponent(query)}`;
        usersP = getFoundUsers(apiUtil, url);
      }
      usersP.then(users => {
        setState({
          data: users,
          loading: false
        });
      }).catch(apiError => {
        setState({
          error: apiError,
          loading: false
        });
      });
    }
  }
};
const getFoundUsers = (apiUtil, url) => apiUtil.jiraGet(url).then(data => data.users.map(userPickerToSelectOption));
const getAssignableUsers = (apiUtil, url) => apiUtil.jiraGet(url).then(data => data.map(toSelectOption));
const userPickerToSelectOption = user => {
  var _user$displayName3;
  return {
    value: user.accountId,
    label: (_user$displayName3 = user.displayName) !== null && _user$displayName3 !== void 0 ? _user$displayName3 : ''
  };
};
const toSelectOption = user => {
  var _user$displayName4;
  return {
    value: user.accountId,
    label: (_user$displayName4 = user.displayName) !== null && _user$displayName4 !== void 0 ? _user$displayName4 : ''
  };
};
const Store = createStore({
  initialState: {
    data: undefined,
    error: undefined,
    loading: false
  },
  actions,
  name: 'UserChoicesService'
});
export const UserChoicesServiceContainer = createContainer(Store);
export const useUserChoicesService = createHook(Store);