// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from '@emotion/styled';
import { N30 } from '@atlaskit/theme/colors';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ChoiceQuestionStyles = styled.div({
  lineHeight: 'initial'
});
export const darkSelectStyles = {
  control: provided => ({
    ...provided,
    backgroundColor: `var(--ds-background-input, ${N30})`,
    borderWidth: '2px',
    borderColor: `var(--ds-border, ${N30})`,
    borderRadius: '3px'
  })
};