import { runInAction } from 'mobx';
import { FormQuestionType, FormStatus } from '../../../form-system/models/Form';
import { RichTextFormat } from '../../models/RichTextFormat';
import { validate } from '../../models/validators';
const shouldOverwriteAnswerWithFieldValueAnswer = fieldValueAnswer => {
  // Assume that if there is a non-empty answer in the field data response, that we should prefer that
  // over whatever is currently in the form. At the time of writing, this only occurs for async choice fields
  // default answer values on portal create / issue forms.
  const isFieldValueAnswerEmpty = Object.keys(fieldValueAnswer).length === 0;
  return !isFieldValueAnswerEmpty;
};
const getRichTextFormat = formQuestionType => {
  if (formQuestionType === FormQuestionType.RichTextParagraph) {
    return RichTextFormat.Adf;
  } else if (formQuestionType === FormQuestionType.TextParagraph) {
    return RichTextFormat.Wiki;
  }
  return undefined;
};
export const loadFieldDataFn = (requestFieldData, errorUtils, isAlwaysOverwriteEmptyLinkedFields = true) => {
  return formStore => {
    return () => {
      // Build a map of all linked Jira fields used by this form:
      const fieldsInUse = formStore.questions.reduce((fieldsInUse, questionStore) => {
        if (questionStore.formQuestion.jiraField) {
          fieldsInUse.push({
            fieldKey: questionStore.formQuestion.jiraField,
            format: getRichTextFormat(questionStore.formQuestion.type)
          });
        }
        return fieldsInUse;
      }, []);
      if (fieldsInUse.length === 0) {
        return Promise.resolve();
      }
      return requestFieldData({
        fields: fieldsInUse
      }).then(fieldData => {
        const validationResult = validate('ApiFieldDataResponseSchema', fieldData);
        if (!validationResult.isValid) {
          errorUtils.reportError(new Error('Response type mismatch'), {
            id: 'ApiFieldDataResponseSchema',
            attributes: {
              errors: JSON.stringify(validationResult.errors)
            }
          });
          return;
        }
        const fieldMap = new Map(Object.entries(fieldData));
        runInAction(() => {
          formStore.questions.forEach(questionStore => {
            if (questionStore.formQuestion.jiraField && fieldMap.has(questionStore.formQuestion.jiraField)) {
              // The API and UI code have slightly different representations of answers, so choices and users need to be converted:
              const issueFieldData = fieldMap.get(questionStore.formQuestion.jiraField);
              const issueFieldValue = issueFieldData.value;
              const answer = {
                ...(issueFieldValue.text && {
                  text: issueFieldValue.text
                }),
                ...(issueFieldValue.adf && {
                  adf: issueFieldValue.adf
                }),
                ...(issueFieldValue.date && {
                  date: issueFieldValue.date
                }),
                ...(issueFieldValue.time && {
                  // jira time value includes seconds which ProForma doesn't use, so trim them
                  time: !!issueFieldValue.time.match(/^\d+:\d+:\d+$/) ? issueFieldValue.time.replace(/:\d+$/, '') : issueFieldValue.time
                }),
                ...(issueFieldValue.choices && {
                  choices: issueFieldValue.choices.map(choice => choice.id)
                }),
                ...(issueFieldValue.users && {
                  users: issueFieldValue.users
                })
              };
              questionStore.setIssueFieldData(issueFieldData);
              if (formStore.status === FormStatus.Open) {
                if (isAlwaysOverwriteEmptyLinkedFields || shouldOverwriteAnswerWithFieldValueAnswer(answer)) {
                  questionStore.setAnswerFromFieldValue(answer);
                  questionStore.setAnswerChoicesAsObjects(issueFieldValue.choices || []);
                }
              }
            }
          });
        });
      });
    };
  };
};