import { FormQuestionType } from '../../../form-system/models';
import { QuestionTypeMessage } from '../../../jira-common';
export function questionTypeToQuestionTypeMessage(questionType) {
  switch (questionType) {
    case FormQuestionType.TextShort:
      return QuestionTypeMessage.TextShortLabel;
    case FormQuestionType.TextLong:
      return QuestionTypeMessage.TextLongLabel;
    case FormQuestionType.TextParagraph:
      return QuestionTypeMessage.PlainTextParagraphLabel;
    case FormQuestionType.RichTextParagraph:
      return QuestionTypeMessage.RichTextParagraphLabel;
    case FormQuestionType.TextEmail:
      return QuestionTypeMessage.TextEmailLabel;
    case FormQuestionType.TextUrl:
      return QuestionTypeMessage.TextUrlLabel;
    case FormQuestionType.ChoiceCascading:
      return QuestionTypeMessage.ChoiceCascadingLabel;
    case FormQuestionType.ChoiceDropdown:
      return QuestionTypeMessage.ChoiceDropdownLabel;
    case FormQuestionType.ChoiceSingle:
      return QuestionTypeMessage.ChoiceSingleLabel;
    case FormQuestionType.ChoiceMultiple:
      return QuestionTypeMessage.ChoiceMultipleLabel;
    case FormQuestionType.ChoiceDropdownMultiple:
      return QuestionTypeMessage.ChoiceDropdownMultipleLabel;
    case FormQuestionType.CmdbObject:
      return QuestionTypeMessage.CmdbObjectLabel;
    case FormQuestionType.Date:
      return QuestionTypeMessage.DateLabel;
    case FormQuestionType.DateTime:
      return QuestionTypeMessage.DateTimeLabel;
    case FormQuestionType.Time:
      return QuestionTypeMessage.TimeLabel;
    case FormQuestionType.Number:
      return QuestionTypeMessage.NumberLabel;
    case FormQuestionType.UserSingle:
      return QuestionTypeMessage.UserSingleLabel;
    case FormQuestionType.UserMultiple:
      return QuestionTypeMessage.UserMultipleLabel;
    default:
      return QuestionTypeMessage.UnsupportedLabel;
  }
}