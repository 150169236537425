import { FormQuestionType } from '../../models';
import { toChoiceQuestionAnswer, toUserPickerQuestionAnswer } from '../../stores';
import { toTextQuestionAnswer } from '../../stores/QuestionStore';
const getCmdbContextValue = question => {
  var _toChoiceQuestionAnsw, _toUserPickerQuestion;
  switch (question.formQuestion.type) {
    case FormQuestionType.TextShort:
    case FormQuestionType.TextLong:
    case FormQuestionType.TextParagraph:
    case FormQuestionType.TextEmail:
    case FormQuestionType.TextUrl:
    case FormQuestionType.Date:
    case FormQuestionType.Number:
      return toTextQuestionAnswer(question.currentAnswer);
    case FormQuestionType.ChoiceDropdown:
    case FormQuestionType.ChoiceSingle:
    case FormQuestionType.ChoiceMultiple:
    case FormQuestionType.ChoiceDropdownMultiple:
    case FormQuestionType.CmdbObject:
      return (_toChoiceQuestionAnsw = toChoiceQuestionAnswer(question.currentAnswer)) === null || _toChoiceQuestionAnsw === void 0 ? void 0 : _toChoiceQuestionAnsw.choices.join(',');
    case FormQuestionType.UserSingle:
    case FormQuestionType.UserMultiple:
      return (_toUserPickerQuestion = toUserPickerQuestionAnswer(question.currentAnswer)) === null || _toUserPickerQuestion === void 0 ? void 0 : _toUserPickerQuestion.map(({
        id
      }) => id).join(',');
    default:
      return undefined;
  }
};
export const getCmdbFieldValueMap = form => {
  const cmdbFieldValueMap = {};
  form.questions.forEach(question => {
    if (question.formQuestion.jiraField) {
      const cmdbContextValue = getCmdbContextValue(question);
      if (cmdbContextValue !== undefined) {
        cmdbFieldValueMap[question.formQuestion.jiraField] = cmdbContextValue;
      }
    }
  });
  return cmdbFieldValueMap;
};