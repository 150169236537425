import { createContainer, createHook, createStore } from 'react-sweet-state';
const actions = {
  load: query => ({
    getState,
    setState
  }, {
    apiUtil
  }) => {
    if (!getState().loading) {
      setState({
        data: undefined,
        error: undefined,
        loading: true
      });
      const uri = `/rest/service-registry-api/service?query=${query || ''}`;
      apiUtil.jiraGet(uri).then(data => data.services.map(affectedService => ({
        value: affectedService.id,
        label: affectedService.name
      }))).then(affectedServices => {
        setState({
          data: affectedServices,
          loading: false
        });
      }).catch(apiError => {
        setState({
          error: apiError,
          loading: false
        });
      });
    }
  }
};
const Store = createStore({
  initialState: {
    data: undefined,
    error: undefined,
    loading: false
  },
  actions,
  name: 'AffectedServicesChoicesService'
});
export const AffectedServicesChoicesServiceContainer = createContainer(Store);
export const useAffectedServicesChoicesService = createHook(Store);