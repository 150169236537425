/* prettier-ignore */
/**
 * NOTE:
 *
 * This file is automatically generated by Traduki 2.0.
 * DO NOT CHANGE IT BY HAND or your changes will be lost.
 */
//Dutch (Netherlands)
export default {
  'proforma-jira-issue-forms.common.ui.footer.create-another-label': 'Nog een issue aanmaken',
  'proforma-jira-issue-forms.common.ui.footer.create-button-label': 'Aanmaken',
  'proforma-jira-issue-forms.common.ui.footer.failed-creating-flag-description': 'Probeer het later opnieuw.',
  'proforma-jira-issue-forms.common.ui.footer.failed-creating-flag-title': 'We konden je issue niet aanmaken.',
  'proforma-jira-issue-forms.common.ui.footer.failed-creating-with-save-errors-flag-description': 'Wijzig ongeldige antwoorden en controleer of alle vereiste velden zijn ingevuld.',
  'proforma-jira-issue-forms.common.ui.footer.successfully-created-flag-copy-link-action': 'Link kopiëren',
  'proforma-jira-issue-forms.common.ui.footer.successfully-created-flag-title': 'Je hebt de issue "{issueKey}" aangemaakt.',
  'proforma-jira-issue-forms.common.ui.footer.successfully-created-flag-view-issue-action': 'Issue bekijken',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-edit-form-link-label-for-project-admin': 'Formulier bewerken',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-is-conditional-action-for-project-admin': 'Aanvraagformulier bewerken',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-is-conditional-description-for-non-project-admin': "Vraag je beheerder om het veld <strong>{formFieldName}</strong> uit de voorwaardelijke sectie te verplaatsen, of de status 'vereist' te verwijderen uit het gekoppelde Jira-veld <strong>{jiraFieldName}</strong> op het <strong>{typeName}</strong> aanvraagformulier.",
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-is-conditional-description-for-project-admin': 'Verplaats het veld <strong>{formFieldName}</strong> uit de voorwaardelijke sectie, of verwijder de status ‘vereist’ uit het gekoppelde Jira-veld <strong>{jiraFieldName}</strong> op het <strong>{typeName}</strong> aanvraagformulier.',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-is-conditional-title': '‘Vereist’ veld in voorwaardelijke sectie',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-missing-description-for-non-project-admin': 'Vraag je beheerder om een veld in dit formulier te koppelen met het Jira-veld <strong>{jiraFieldName}</strong> op het <strong>{typeName}</strong> aanvraagformulier.',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-missing-description-for-project-admin': 'Koppel een veld in dit formulier met het Jira-veld <strong>{jiraFieldName}</strong> op het <strong>{typeName}</strong> aanvraagformulier.',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-missing-title': '‘Vereist’ veld is niet gekoppeld',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-needs-to-be-mandatory-description-for-non-project-admin': 'Vraag je beheerder om het veld <strong>{formFieldName}</strong> op dit formulier te markeren als ‘vereist’ zodat deze overeenkomt met het gekoppelde Jira-veld <strong>{jiraFieldName}</strong> op het <strong>{typeName}</strong> aanvraagformulier.',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-needs-to-be-mandatory-description-for-project-admin': 'Markeer het veld <strong>{formFieldName}</strong> op dit formulier als ‘vereist’ zodat deze overeenkomt met het gekoppelde Jira-veld <strong>{jiraFieldName}</strong> op het <strong>{typeName}</strong> aanvraagformulier.',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-needs-to-be-mandatory-title': '‘Vereist’ veld komt niet overeen',
  'proforma-jira-issue-forms.common.utils.process-form-load-error.form-loading-failed-flag-description': 'Vernieuw de pagina en probeer het opnieuw.',
  'proforma-jira-issue-forms.common.utils.process-form-load-error.form-loading-failed-flag-title': 'We konden je formulier niet laden',
  'proforma-jira-issue-forms.controllers.form-store.object-search-error-description': 'Verwijder attributen die niet meer bestaan in de filter voor configuratie van Assets aangepaste velden en zorg ervoor dat het AQL-verzoek geldig is.',
  'proforma-jira-issue-forms.controllers.form-store.object-search-error-title': 'We konden geen objecten ophalen voor dit veld',
  'proforma-jira-issue-forms.ui.direct.embed.successfully-created-button-embed': 'Nog een issue aanmaken',
  'proforma-jira-issue-forms.ui.direct.embed.successfully-created-description-with-link-embed': 'Bekijk <a>issueKey</a> om deze issue bij te werken of te beheren.',
  'proforma-jira-issue-forms.ui.direct.embed.successfully-created-title-embed': 'We hebben je issue aangemaakt',
  'proforma-jira-issue-forms.ui.general.direct-link-section-message-embed': 'Maak issues aan met behulp van ingebedde formulieren door deze link in een Atlassian-site te plakken. <a>Link kopiëren</a>',
  'proforma-jira-issue-forms.ui.general.header.error-loading-project-associated-forms-description': 'Vernieuw de pagina en probeer het opnieuw.',
  'proforma-jira-issue-forms.ui.general.header.error-loading-project-associated-forms-title': 'We kunnen je formulieren niet laden',
  'proforma-jira-issue-forms.ui.general.header.error-loading-projects-description': 'Vernieuw de pagina en probeer het opnieuw.',
  'proforma-jira-issue-forms.ui.general.header.error-loading-projects-title': 'We kunnen je projecten niet laden',
  'proforma-jira-issue-forms.ui.general.header.form-placeholder': 'Een formulier selecteren',
  'proforma-jira-issue-forms.ui.general.header.issue-type-placeholder': 'Een issuetype selecteren',
  'proforma-jira-issue-forms.ui.general.header.page-description-embed': 'Maak issues aan met behulp van een formulier of rechtstreeks vanuit een link naar een formulier. <a>Meer informatie over het gebruiken van formulieren om issues aan te maken.</a>',
  'proforma-jira-issue-forms.ui.general.header.page-header': 'Issue aanmaken',
  'proforma-jira-issue-forms.ui.general.header.project-has-no-associated-forms-jsm': 'Dit serviceproject heeft geen bijbehorende formulieren.',
  'proforma-jira-issue-forms.ui.general.header.project-has-no-associated-forms-jsw': 'Dit softwareproject heeft geen bijbehorende formulieren.',
  'proforma-jira-issue-forms.ui.general.header.project-has-no-associated-forms-jwm': 'Dit zakelijke project heeft geen bijbehorende formulieren.',
  'proforma-jira-issue-forms.ui.general.header.project-placeholder': 'Een project selecteren',
  'proforma-jira-issue-forms.ui.general.header.request-type-placeholder': 'Een aanvraagtype selecteren'
};