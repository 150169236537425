/* prettier-ignore */
/**
 * NOTE:
 *
 * This file is automatically generated by Traduki 2.0.
 * DO NOT CHANGE IT BY HAND or your changes will be lost.
 */
//Danish (Denmark)
export default {
  'proforma-jira-issue-forms.common.ui.footer.create-another-label': 'Opret en anden sag',
  'proforma-jira-issue-forms.common.ui.footer.create-button-label': 'Opret',
  'proforma-jira-issue-forms.common.ui.footer.failed-creating-flag-description': 'Prøv igen senere.',
  'proforma-jira-issue-forms.common.ui.footer.failed-creating-flag-title': 'Vi kunne ikke oprette din sag.',
  'proforma-jira-issue-forms.common.ui.footer.failed-creating-with-save-errors-flag-description': 'Løs eventuelle ugyldige svar, og kontrollér, at alle påkrævede felter er udfyldt.',
  'proforma-jira-issue-forms.common.ui.footer.successfully-created-flag-copy-link-action': 'Kopiér link',
  'proforma-jira-issue-forms.common.ui.footer.successfully-created-flag-title': 'Du har oprettet sagen "{issueKey}".',
  'proforma-jira-issue-forms.common.ui.footer.successfully-created-flag-view-issue-action': 'Vis sag',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-edit-form-link-label-for-project-admin': 'Rediger formular',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-is-conditional-action-for-project-admin': 'Rediger anmodningsformular',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-is-conditional-description-for-non-project-admin': 'Bed din administrator om at flytte feltet <strong>{formFieldName}</strong> ud af betingelsessektionen, eller fjern statussen &quot;Påkrævet&quot; fra det tilknyttede Jira-felt <strong>{jiraFieldName}</strong> i anmodningsformularen <strong>{typeName}</strong>.',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-is-conditional-description-for-project-admin': 'Flyt feltet <strong>{formFieldName}</strong> ud af betingelsessektionen, eller fjern statussen &quot;Påkrævet&quot; fra det tilknyttede Jira-felt <strong>{jiraFieldName}</strong> i anmodningsformularen <strong>{typeName}</strong>.',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-is-conditional-title': '"Påkrævet" felt i betingelsesafsnittet',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-missing-description-for-non-project-admin': 'Bed din administrator om at knytte et felt i denne formular til Jira-feltet <strong>{jiraFieldName}</strong> i anmodningsformularen <strong>{typeName}</strong>.',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-missing-description-for-project-admin': 'Knyt et felt i denne formular til Jira-feltet <strong>{jiraFieldName}</strong> i anmodningsformularen <strong>{typeName}</strong>.',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-missing-title': 'Det "påkrævede" felt er ikke tilknyttet',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-needs-to-be-mandatory-description-for-non-project-admin': 'Bed din administrator om at markere feltet <strong>{formFieldName}</strong> i denne formular som &quot;Påkrævet&quot; for at matche det tilknyttede Jira-felt <strong>{jiraFieldName}</strong> i anmodningsformularen <strong>{typeName}</strong>.',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-needs-to-be-mandatory-description-for-project-admin': 'Markér feltet <strong>{formFieldName}</strong> i denne formular som &quot;Påkrævet&quot; for at matche det tilknyttede Jira-felt <strong>{jiraFieldName}</strong> i anmodningsformularen <strong>{typeName}</strong>.',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-needs-to-be-mandatory-title': 'Det "påkrævede" felt matcher ikke',
  'proforma-jira-issue-forms.common.utils.process-form-load-error.form-loading-failed-flag-description': 'Opdater siden, og prøv igen.',
  'proforma-jira-issue-forms.common.utils.process-form-load-error.form-loading-failed-flag-title': 'Vi kunne ikke indlæse din formular',
  'proforma-jira-issue-forms.controllers.form-store.object-search-error-description': 'Fjern attributter, der ikke længere findes i konfigurationsfilteret for brugerdefineret Aktiver-felt, og sørg for, at AQL-forespørgslen er gyldig.',
  'proforma-jira-issue-forms.controllers.form-store.object-search-error-title': 'Vi kunne ikke hente nogen objekter for dette felt',
  'proforma-jira-issue-forms.ui.direct.embed.successfully-created-button-embed': 'Opret en anden sag',
  'proforma-jira-issue-forms.ui.direct.embed.successfully-created-description-with-link-embed': 'Se <a>issueKey</a> for at opdatere eller administrere denne sag.',
  'proforma-jira-issue-forms.ui.direct.embed.successfully-created-title-embed': 'Vi har oprettet din sag',
  'proforma-jira-issue-forms.ui.general.direct-link-section-message-embed': 'Opret sager ved hjælp af indlejrede formularer ved at indsætte dette link på et Atlassian-websted. <a>Kopiér link</a>',
  'proforma-jira-issue-forms.ui.general.header.error-loading-project-associated-forms-description': 'Opdater siden, og prøv igen.',
  'proforma-jira-issue-forms.ui.general.header.error-loading-project-associated-forms-title': 'Vi har problemer med at indlæse dine formularer',
  'proforma-jira-issue-forms.ui.general.header.error-loading-projects-description': 'Opdater siden, og prøv igen.',
  'proforma-jira-issue-forms.ui.general.header.error-loading-projects-title': 'Vi har problemer med at indlæse dine projekter',
  'proforma-jira-issue-forms.ui.general.header.form-placeholder': 'Vælg en formular',
  'proforma-jira-issue-forms.ui.general.header.issue-type-placeholder': 'Vælg en sagstype',
  'proforma-jira-issue-forms.ui.general.header.page-description-embed': 'Opret sager ved hjælp af en formular eller direkte fra et link til en formular. <a>Få mere at vide om brug af formularer til at oprette sager.</a>',
  'proforma-jira-issue-forms.ui.general.header.page-header': 'Opret sag',
  'proforma-jira-issue-forms.ui.general.header.project-has-no-associated-forms-jsm': 'Dette serviceprojekt har ingen tilknyttede formularer.',
  'proforma-jira-issue-forms.ui.general.header.project-has-no-associated-forms-jsw': 'Dette softwareprojekt har ingen tilknyttede formularer.',
  'proforma-jira-issue-forms.ui.general.header.project-has-no-associated-forms-jwm': 'Dette forretningsprojekt har ingen tilknyttede formularer.',
  'proforma-jira-issue-forms.ui.general.header.project-placeholder': 'Vælg et projekt',
  'proforma-jira-issue-forms.ui.general.header.request-type-placeholder': 'Vælg en anmodningstype'
};