import { FormStatus, FormVisibility } from '../../models/Form';
export function convertTemplateFormToUnsavedForm(templateForm) {
  const convertedForm = {
    id: undefined,
    updated: templateForm.updated,
    design: templateForm.design,
    ...(templateForm.publish && {
      publish: templateForm.publish
    }),
    state: createStateFromTemplateForm(templateForm)
  };
  return convertedForm;
}
function createStateFromTemplateForm(templateForm) {
  return {
    visibility: FormVisibility.Internal,
    status: FormStatus.Open,
    answers: populateDefaultAnswers(templateForm.design.questions)
  };
}
function populateDefaultAnswers(questions) {
  const formAnswers = {};
  Object.keys(questions).forEach(key => {
    const question = questions[key];
    if (question.defaultAnswer) {
      formAnswers[key] = question.defaultAnswer;
    }
  });
  return formAnswers;
}