// Jira fields that should ONLY be rendered as dropdown multi-selects:
export const AFFECTED_SERVICES_SCHEMA_CUSTOM = 'com.atlassian.jira.plugins.service-entity:service-entity-field-cftype';
export const CMDB_SCHEMA_CUSTOM = 'com.atlassian.jira.plugins.cmdb:cmdb-object-cftype';
export const LABELS_SCHEMA_CUSTOM = 'com.atlassian.jira.plugin.system.customfieldtypes:labels';
export const LABELS_SCHEMA_SYSTEM = 'labels';

// Fields where choices/options are loaded async (e.g. on user search)
export const asyncChoicesFieldSchemaCustoms = [AFFECTED_SERVICES_SCHEMA_CUSTOM, CMDB_SCHEMA_CUSTOM, LABELS_SCHEMA_CUSTOM];
export const asyncChoicesFieldSchemaSystems = [LABELS_SCHEMA_SYSTEM];
export const isAsyncCreatable = question => {
  var _question$issueFieldD, _question$issueFieldD2, _question$issueFieldD3, _question$issueFieldD4;
  return ((_question$issueFieldD = question.issueFieldData) === null || _question$issueFieldD === void 0 ? void 0 : (_question$issueFieldD2 = _question$issueFieldD.schema) === null || _question$issueFieldD2 === void 0 ? void 0 : _question$issueFieldD2.custom) === LABELS_SCHEMA_CUSTOM || ((_question$issueFieldD3 = question.issueFieldData) === null || _question$issueFieldD3 === void 0 ? void 0 : (_question$issueFieldD4 = _question$issueFieldD3.schema) === null || _question$issueFieldD4 === void 0 ? void 0 : _question$issueFieldD4.system) === LABELS_SCHEMA_SYSTEM;
};