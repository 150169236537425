import { useCallback, useMemo } from 'react';
import { createHook, createStore } from 'react-sweet-state';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { fireTrackAnalytics } from '@atlassian/analytics-bridge';
import { Action, ActionSubject } from '@atlassian/proforma-utils/analytics';
const initialState = {
  errorsByQuestionId: {}
};
const actions = {
  set: errorsByQuestionId => ({
    setState
  }) => setState({
    errorsByQuestionId
  }),
  setFromJiraFieldErrors: (form, jiraFieldErrors) => ({
    setState
  }) => {
    const formQuestionsByJiraField = form.questions.reduce((accQuestions, question) => {
      const jiraField = question.formQuestion.jiraField;
      if (!jiraField) {
        return accQuestions;
      }
      return {
        ...accQuestions,
        [jiraField]: question
      };
    }, {});
    const errorsByQuestionId = Object.entries(jiraFieldErrors).reduce((accErrorsByQuestionId, [jiraField, error]) => {
      var _formQuestionsByJiraF, _formQuestionsByJiraF2;
      const questionId = ((_formQuestionsByJiraF = formQuestionsByJiraField[jiraField]) === null || _formQuestionsByJiraF === void 0 ? void 0 : _formQuestionsByJiraF.id) && Number((_formQuestionsByJiraF2 = formQuestionsByJiraField[jiraField]) === null || _formQuestionsByJiraF2 === void 0 ? void 0 : _formQuestionsByJiraF2.id);
      if (!questionId) {
        return accErrorsByQuestionId;
      }
      return {
        ...accErrorsByQuestionId,
        [questionId]: error
      };
    }, {});
    setState({
      ...initialState,
      errorsByQuestionId
    });
  },
  clear: () => ({
    setState
  }) => setState(initialState)
};
const Store = createStore({
  initialState: initialState,
  actions,
  name: `FormSaveErrors`
});
const useFormSaveErrors = createHook(Store);
export const useFormSaveErrorsActions = () => {
  const {
    createAnalyticsEvent
  } = useAnalyticsEvents();
  const [, {
    set,
    setFromJiraFieldErrors,
    clear
  }] = useFormSaveErrors();
  const setWithAnalytics = useCallback(errorsByQuestionId => {
    if (Object.keys(errorsByQuestionId).length > 0) {
      fireTrackAnalytics(createAnalyticsEvent({}), `${ActionSubject.FORM_SAVE} ${Action.STOPPED}`);
    }
    set(errorsByQuestionId);
  }, [createAnalyticsEvent, set]);
  const setWithJiraFieldErrorsWithAnalytics = useCallback((form, jiraFieldErrors) => {
    if (Object.keys(jiraFieldErrors).length > 0) {
      fireTrackAnalytics(createAnalyticsEvent({}), `${ActionSubject.FORM_SAVE} ${Action.STOPPED}`);
    }
    setFromJiraFieldErrors(form, jiraFieldErrors);
  }, [createAnalyticsEvent, setFromJiraFieldErrors]);
  return useMemo(() => ({
    set: setWithAnalytics,
    setFromJiraFieldErrors: setWithJiraFieldErrorsWithAnalytics,
    clear
  }), [clear, setWithAnalytics, setWithJiraFieldErrorsWithAnalytics]);
};
export const useFormSaveQuestionError = questionId => {
  const [{
    errorsByQuestionId
  }] = useFormSaveErrors();
  return errorsByQuestionId[questionId];
};