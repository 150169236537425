/**
 * NOTE:
 *
 * This file is automatically generated.
 * DO NOT UPDATE IT BY HAND. Instead, run yarn generate-i18n-index in @atlassian/proforma-utils.
 */
const lazyMessages = {
  cs: () => import( /* webpackChunkName: "@atlaskit-internal_proforma-common-core/locale/cs" */'./cs'),
  da: () => import( /* webpackChunkName: "@atlaskit-internal_proforma-common-core/locale/da" */'./da'),
  de: () => import( /* webpackChunkName: "@atlaskit-internal_proforma-common-core/locale/de" */'./de'),
  en: () => import( /* webpackChunkName: "@atlaskit-internal_proforma-common-core/locale/en" */'./en'),
  en_GB: () => import( /* webpackChunkName: "@atlaskit-internal_proforma-common-core/locale/en_GB" */'./en_GB'),
  en_ZZ: () => import( /* webpackChunkName: "@atlaskit-internal_proforma-common-core/locale/en_ZZ" */'./en_ZZ'),
  es: () => import( /* webpackChunkName: "@atlaskit-internal_proforma-common-core/locale/es" */'./es'),
  fi: () => import( /* webpackChunkName: "@atlaskit-internal_proforma-common-core/locale/fi" */'./fi'),
  fr: () => import( /* webpackChunkName: "@atlaskit-internal_proforma-common-core/locale/fr" */'./fr'),
  hu: () => import( /* webpackChunkName: "@atlaskit-internal_proforma-common-core/locale/hu" */'./hu'),
  it: () => import( /* webpackChunkName: "@atlaskit-internal_proforma-common-core/locale/it" */'./it'),
  ja: () => import( /* webpackChunkName: "@atlaskit-internal_proforma-common-core/locale/ja" */'./ja'),
  ko: () => import( /* webpackChunkName: "@atlaskit-internal_proforma-common-core/locale/ko" */'./ko'),
  nb: () => import( /* webpackChunkName: "@atlaskit-internal_proforma-common-core/locale/nb" */'./nb'),
  nl: () => import( /* webpackChunkName: "@atlaskit-internal_proforma-common-core/locale/nl" */'./nl'),
  pl: () => import( /* webpackChunkName: "@atlaskit-internal_proforma-common-core/locale/pl" */'./pl'),
  pt_BR: () => import( /* webpackChunkName: "@atlaskit-internal_proforma-common-core/locale/pt_BR" */'./pt_BR'),
  ru: () => import( /* webpackChunkName: "@atlaskit-internal_proforma-common-core/locale/ru" */'./ru'),
  sv: () => import( /* webpackChunkName: "@atlaskit-internal_proforma-common-core/locale/sv" */'./sv'),
  th: () => import( /* webpackChunkName: "@atlaskit-internal_proforma-common-core/locale/th" */'./th'),
  tr: () => import( /* webpackChunkName: "@atlaskit-internal_proforma-common-core/locale/tr" */'./tr'),
  uk: () => import( /* webpackChunkName: "@atlaskit-internal_proforma-common-core/locale/uk" */'./uk'),
  vi: () => import( /* webpackChunkName: "@atlaskit-internal_proforma-common-core/locale/vi" */'./vi'),
  zh: () => import( /* webpackChunkName: "@atlaskit-internal_proforma-common-core/locale/zh" */'./zh'),
  zh_TW: () => import( /* webpackChunkName: "@atlaskit-internal_proforma-common-core/locale/zh_TW" */'./zh_TW')
};
export default lazyMessages;