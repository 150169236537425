export const getAnalyticsAttributesForIssueFormsDirect = settings => {
  const {
    projectId,
    projectType,
    templateFormId,
    isSimplifiedProject,
    issueTypeId,
    requestTypeId,
    userIsProjectAdmin,
    isEmbed
  } = settings;
  return {
    projectId,
    projectType,
    templateFormId,
    isSimplifiedProject,
    issueTypeId,
    requestTypeId,
    isProjectAdmin: userIsProjectAdmin || false,
    isEmbed
  };
};