/* prettier-ignore */
/**
 * NOTE:
 *
 * This file is automatically generated by Traduki 2.0.
 * DO NOT CHANGE IT BY HAND or your changes will be lost.
 */
//German (Germany)
export default {
  'proforma-jira-issue-forms.common.ui.footer.create-another-label': 'Weiteren Vorgang erstellen',
  'proforma-jira-issue-forms.common.ui.footer.create-button-label': 'Erstellen',
  'proforma-jira-issue-forms.common.ui.footer.failed-creating-flag-description': 'Bitte versuchen Sie es später erneut.',
  'proforma-jira-issue-forms.common.ui.footer.failed-creating-flag-title': 'Ihr Vorgang konnte nicht erstellt werden.',
  'proforma-jira-issue-forms.common.ui.footer.failed-creating-with-save-errors-flag-description': 'Bitte bearbeiten Sie alle ungültigen Antworten und stellen Sie sicher, dass alle erforderlichen Felder ausgefüllt sind.',
  'proforma-jira-issue-forms.common.ui.footer.successfully-created-flag-copy-link-action': 'Link kopieren',
  'proforma-jira-issue-forms.common.ui.footer.successfully-created-flag-title': 'Sie haben den Vorgang "{issueKey}" erstellt.',
  'proforma-jira-issue-forms.common.ui.footer.successfully-created-flag-view-issue-action': 'Vorgang anzeigen',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-edit-form-link-label-for-project-admin': 'Formular bearbeiten',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-is-conditional-action-for-project-admin': 'Anfrageformular bearbeiten',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-is-conditional-description-for-non-project-admin': 'Bitten Sie Ihren Administrator, das Feld <strong>{formFieldName}</strong> aus dem bedingten Abschnitt zu verschieben, oder entfernen Sie den Status &quot;Erforderlich&quot; aus dem verknüpften Jira-Feld <strong>{jiraFieldName}</strong> im Anfrageformular <strong>{typeName}</strong>.',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-is-conditional-description-for-project-admin': 'Verschieben Sie das Feld <strong>{formFieldName}</strong> aus dem bedingten Abschnitt oder entfernen Sie den Status &quot;Erforderlich&quot; aus dem Jira-Feld <strong>{jiraFieldName}</strong> im Anfrageformular <strong>{typeName}</strong>.',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-is-conditional-title': '"Erforderliches" Feld im bedingten Abschnitt',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-missing-description-for-non-project-admin': 'Bitten Sie Ihren Administrator, ein Feld in diesem Formular mit dem Jira-Feld <strong>{jiraFieldName}</strong> im Anfrageformular <strong>{typeName}</strong> zu verknüpfen.',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-missing-description-for-project-admin': 'Verknüpfen Sie ein Feld in diesem Formular mit dem Jira-Feld <strong>{jiraFieldName}</strong> im Anfrageformular <strong>{typeName}</strong>.',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-missing-title': '"Erforderliches" Feld ist nicht verknüpft',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-needs-to-be-mandatory-description-for-non-project-admin': 'Bitten Sie Ihren Administrator, das Feld <strong>{formFieldName}</strong> in diesem Formular als &quot;Erforderlich&quot; zu kennzeichnen, damit es mit dem verknüpften Jira-Feld <strong>{jiraFieldName}</strong> im Anfrageformular <strong>{typeName}</strong> übereinstimmt.',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-needs-to-be-mandatory-description-for-project-admin': 'Kennzeichnen Sie das Feld <strong>{formFieldName}</strong> in diesem Formular als &quot;Pflichtfeld&quot;, damit es mit seinem verknüpften Jira-Feld <strong>{jiraFieldName}</strong> im Anfrageformular <strong>{typeName}</strong> übereinstimmt.',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-needs-to-be-mandatory-title': '"Erforderliches" Feld stimmt nicht überein',
  'proforma-jira-issue-forms.common.utils.process-form-load-error.form-loading-failed-flag-description': 'Aktualisieren Sie die Seite und versuchen Sie es dann erneut.',
  'proforma-jira-issue-forms.common.utils.process-form-load-error.form-loading-failed-flag-title': 'Ihr Formular konnte nicht geladen werden',
  'proforma-jira-issue-forms.controllers.form-store.object-search-error-description': 'Entfernen Sie Attribute, die nicht mehr existieren, aus dem Konfigurationsfilter für benutzerdefinierte Assets-Felder und stellen Sie sicher, dass die AQL-Abfrage gültig ist.',
  'proforma-jira-issue-forms.controllers.form-store.object-search-error-title': 'Für dieses Feld konnten keine Objekte abgerufen werden',
  'proforma-jira-issue-forms.ui.direct.embed.successfully-created-button-embed': 'Weiteren Vorgang erstellen',
  'proforma-jira-issue-forms.ui.direct.embed.successfully-created-description-with-link-embed': '<a>issueKey</a> anzeigen, um diesen Vorgang zu aktualisieren oder zu verwalten.',
  'proforma-jira-issue-forms.ui.direct.embed.successfully-created-title-embed': 'Ihr Vorgang wurde erstellt',
  'proforma-jira-issue-forms.ui.general.direct-link-section-message-embed': 'Erstellen Sie Vorgänge mithilfe von eingebetteten Formularen, indem Sie diesen Link auf einer beliebigen Atlassian-Site einfügen. <a>Link kopieren</a>',
  'proforma-jira-issue-forms.ui.general.header.error-loading-project-associated-forms-description': 'Aktualisieren Sie die Seite und versuchen Sie es dann erneut.',
  'proforma-jira-issue-forms.ui.general.header.error-loading-project-associated-forms-title': 'Beim Laden Ihrer Formulare ist ein Problem aufgetreten',
  'proforma-jira-issue-forms.ui.general.header.error-loading-projects-description': 'Aktualisieren Sie die Seite und versuchen Sie es dann erneut.',
  'proforma-jira-issue-forms.ui.general.header.error-loading-projects-title': 'Beim Laden Ihrer Projekte ist ein Problem aufgetreten',
  'proforma-jira-issue-forms.ui.general.header.form-placeholder': 'Formular auswählen',
  'proforma-jira-issue-forms.ui.general.header.issue-type-placeholder': 'Vorgangstyp auswählen',
  'proforma-jira-issue-forms.ui.general.header.page-description-embed': 'Erstellen Sie Vorgänge mithilfe eines Formulars oder direkt über einen Link zu einem Formular. <a>Hier finden Sie weitere Informationen zum Erstellen von Vorgängen mithilfe von Formularen.</a>',
  'proforma-jira-issue-forms.ui.general.header.page-header': 'Vorgang erstellen',
  'proforma-jira-issue-forms.ui.general.header.project-has-no-associated-forms-jsm': 'Für dieses Serviceprojekt gibt es keine zugehörigen Formulare.',
  'proforma-jira-issue-forms.ui.general.header.project-has-no-associated-forms-jsw': 'Für dieses Softwareprojekt gibt es keine zugehörigen Formulare.',
  'proforma-jira-issue-forms.ui.general.header.project-has-no-associated-forms-jwm': 'Für dieses Geschäftsprojekt gibt es keine zugehörigen Formulare.',
  'proforma-jira-issue-forms.ui.general.header.project-placeholder': 'Projekt auswählen',
  'proforma-jira-issue-forms.ui.general.header.request-type-placeholder': 'Anfragetyp auswählen'
};