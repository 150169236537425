/**
 * At the moment straight copy from jira/src/packages/issue/format-date/src/common/constants.tsx
 *
 * If you remove this file, please remove the comment from the above constants file.
 */

/**
 * Long format of a date time, eg "Jun 16, 1971, 2:34 AM" with 12h/24h formatting resolved from locale
 */
export const longDateTimeFormat = {
  day: '2-digit',
  month: 'short',
  year: 'numeric',
  hour: 'numeric',
  minute: 'numeric'
};

/**
 * Long format of a date without time, eg "June 16, 1971"
 */
export const longDateOnlyFormat = {
  day: '2-digit',
  month: 'short',
  year: 'numeric'
};

/**
 * Long format of a time without time, eg "2:34 AM"
 */
export const longTimeOnlyFormat = {
  hour: 'numeric',
  minute: 'numeric'
};