import { defineMessages } from 'react-intl-next';
export const messages = defineMessages({
  tooltip: {
    id: 'proforma-form-builder.src.components.questiontypes.linked-field-icon.tooltip',
    defaultMessage: 'Linked to ‘{fieldName}‘ Jira field',
    description: 'Text for a tooltip indicating the form field is linked to a Jira field.'
  },
  unknownField: {
    id: 'proforma-form-builder.src.components.questiontypes.linked-field-icon.unknown-field',
    defaultMessage: 'Linked to Jira field',
    description: 'Text for a tooltip indicating the form field is linked to an unknown Jira field.'
  }
});