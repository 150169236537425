import { defineMessages } from 'react-intl-next';
export const messages = defineMessages({
  readOnlyReasonAnonymousMessage: {
    id: 'form-system.renderQuestion.read-only-reason-anonymous-message',
    defaultMessage: 'Log in with a valid account to access this field.',
    description: 'The question is read only because the user is anonymous message to display when mousing over an icon.'
  },
  readOnlyReasonUnlicensedMessage: {
    id: 'form-system.renderQuestion.read-only-reason-unlicensed-message',
    defaultMessage: 'This field is only available to users with Jira access.',
    description: 'The question is read only because the user does not have a Jira license message to display when mousing over an icon.'
  },
  emptyFormName: {
    id: 'form-system.headerBar.emptyFormName',
    defaultMessage: 'Untitled Form',
    description: 'A placeholder for a form with an empty name.'
  }
});