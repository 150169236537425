/* prettier-ignore */
/**
 * NOTE:
 *
 * This file is automatically generated by Traduki 2.0.
 * DO NOT CHANGE IT BY HAND or your changes will be lost.
 */
//Czech
export default {
  'proforma-jira-issue-forms.common.ui.footer.create-another-label': 'Vytvořit další požadavek',
  'proforma-jira-issue-forms.common.ui.footer.create-button-label': 'Vytvořit',
  'proforma-jira-issue-forms.common.ui.footer.failed-creating-flag-description': 'Zkuste to znovu později.',
  'proforma-jira-issue-forms.common.ui.footer.failed-creating-flag-title': 'Váš požadavek se nepodařilo vytvořit.',
  'proforma-jira-issue-forms.common.ui.footer.failed-creating-with-save-errors-flag-description': 'Vyřešte všechny neplatné odpovědi a zkontrolujte, zda jsou vyplněna všechna povinná pole.',
  'proforma-jira-issue-forms.common.ui.footer.successfully-created-flag-copy-link-action': 'Kopírovat odkaz',
  'proforma-jira-issue-forms.common.ui.footer.successfully-created-flag-title': 'Vytvořili jste požadavek "{issueKey}".',
  'proforma-jira-issue-forms.common.ui.footer.successfully-created-flag-view-issue-action': 'Zobrazit požadavek',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-edit-form-link-label-for-project-admin': 'Upravit formulář',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-is-conditional-action-for-project-admin': 'Upravit formulář žádosti',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-is-conditional-description-for-non-project-admin': 'Požádejte správce, aby přesunul pole <strong>{formFieldName}</strong> z podmíněné sekce nebo zrušil příznak „povinné“ u propojeného pole <strong>{jiraFieldName}</strong> v Jira ve formuláři žádosti typu <strong>{typeName}</strong>.',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-is-conditional-description-for-project-admin': 'Přesuňte pole <strong>{formFieldName}</strong> z podmíněné sekce nebo zrušte příznak „povinné“ u propojeného pole <strong>{jiraFieldName}</strong> v Jira ve formuláři žádosti typu <strong>{typeName}</strong>.',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-is-conditional-title': 'Povinné pole v podmíněné sekci',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-missing-description-for-non-project-admin': 'Požádejte správce, aby propojil pole v tomto formuláři s polem Jira <strong>{jiraFieldName}</strong> ve formuláři žádosti typu <strong>{typeName}</strong>.',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-missing-description-for-project-admin': 'Propojte pole v tomto formuláři s polem Jira <strong>{jiraFieldName}</strong> ve formuláři žádosti <strong>{typeName}</strong>.',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-missing-title': 'Povinné pole není připojeno',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-needs-to-be-mandatory-description-for-non-project-admin': 'Požádejte správce, aby označil pole <strong>{formFieldName}</strong> v tomto formuláři jako „povinné“, aby odpovídalo propojenému poli <strong>{jiraFieldName}</strong> v Jira ve formuláři žádosti typu <strong>{typeName}</strong>.',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-needs-to-be-mandatory-description-for-project-admin': 'Označte pole <strong>{formFieldName}</strong> v tomto formuláři jako „povinné“, aby odpovídalo propojenému poli <strong>{jiraFieldName}</strong> v Jira ve formuláři žádosti typu <strong>{typeName}</strong>.',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-needs-to-be-mandatory-title': 'Povinné pole neodpovídá',
  'proforma-jira-issue-forms.common.utils.process-form-load-error.form-loading-failed-flag-description': 'Obnovte stránku a zkuste to znovu.',
  'proforma-jira-issue-forms.common.utils.process-form-load-error.form-loading-failed-flag-title': 'Váš formulář se nepodařilo načíst',
  'proforma-jira-issue-forms.controllers.form-store.object-search-error-description': 'Odeberte atributy, které už neexistují ve filtru konfigurace vlastních polí Aktiv, a zkontrolujte, zda je dotaz AQL platný.',
  'proforma-jira-issue-forms.controllers.form-store.object-search-error-title': 'Pro toto pole se nepodařilo načíst žádné objekty',
  'proforma-jira-issue-forms.ui.direct.embed.successfully-created-button-embed': 'Vytvořit další požadavek',
  'proforma-jira-issue-forms.ui.direct.embed.successfully-created-description-with-link-embed': 'Zobrazte si požadavek <a>issueKey</a> a budete jej moci aktualizovat nebo spravovat.',
  'proforma-jira-issue-forms.ui.direct.embed.successfully-created-title-embed': 'Váš požadavek byl vytvořen',
  'proforma-jira-issue-forms.ui.general.direct-link-section-message-embed': 'Vytvářejte požadavky pomocí vložených formulářů vložením tohoto odkazu na libovolný web Atlassian. <a>Kopírovat odkaz</a>',
  'proforma-jira-issue-forms.ui.general.header.error-loading-project-associated-forms-description': 'Obnovte stránku a zkuste to znovu.',
  'proforma-jira-issue-forms.ui.general.header.error-loading-project-associated-forms-title': 'Při načítání vašich formulářů se vyskytly problémy',
  'proforma-jira-issue-forms.ui.general.header.error-loading-projects-description': 'Obnovte stránku a zkuste to znovu.',
  'proforma-jira-issue-forms.ui.general.header.error-loading-projects-title': 'Při načítání vašich projektů se vyskytly problémy',
  'proforma-jira-issue-forms.ui.general.header.form-placeholder': 'Vyberte formulář.',
  'proforma-jira-issue-forms.ui.general.header.issue-type-placeholder': 'Vyberte typ požadavku.',
  'proforma-jira-issue-forms.ui.general.header.page-description-embed': 'Vytvářejte požadavky pomocí formuláře nebo přímého odkazu na formulář. <a>Další informace o používání formulářů k vytváření požadavků</a>',
  'proforma-jira-issue-forms.ui.general.header.page-header': 'Vytvořit požadavek',
  'proforma-jira-issue-forms.ui.general.header.project-has-no-associated-forms-jsm': 'Tento projekt služby nemá žádné přidružené formuláře.',
  'proforma-jira-issue-forms.ui.general.header.project-has-no-associated-forms-jsw': 'Tento softwarový projekt nemá žádné přidružené formuláře.',
  'proforma-jira-issue-forms.ui.general.header.project-has-no-associated-forms-jwm': 'Tento obchodní projekt nemá žádné přidružené formuláře.',
  'proforma-jira-issue-forms.ui.general.header.project-placeholder': 'Vyberte projekt.',
  'proforma-jira-issue-forms.ui.general.header.request-type-placeholder': 'Vyberte typ žádosti.'
};