import { createContainer, createHook, createStore } from 'react-sweet-state';
const actions = {
  load: formDetails => ({
    getState,
    setState
  }, {
    apiUtil
  }) => {
    if (!getState().loading) {
      setState({
        data: undefined,
        error: undefined,
        loading: true
      });
      const typeSegments = formDetails.requestType ? `requesttype/${formDetails.requestType.id}` : `issuetype/${formDetails.issueType.id}`;
      apiUtil.get(`/api/3/createissue/form/${formDetails.projectId}/${formDetails.projectFormId}/${typeSegments}/formchoices`).then(response => {
        setState({
          data: response,
          loading: false
        });
      }).catch(apiError => {
        setState({
          error: apiError,
          loading: false
        });
      });
    }
  }
};
const Store = createStore({
  initialState: {
    data: undefined,
    error: undefined,
    loading: false
  },
  actions,
  name: 'FormChoicesService'
});
export const FormChoicesServiceContainer = createContainer(Store);
export const useFormChoicesService = createHook(Store);