// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from '@emotion/styled';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const IssueFormsFooter = styled.div({
  display: 'flex',
  flexGrow: 1,
  maxWidth: '800px',
  marginLeft: "var(--ds-space-200, 16px)",
  marginRight: "var(--ds-space-200, 16px)"
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const IssueFormsFooterLeftWrapper = styled.div({
  alignItems: 'center',
  display: 'flex',
  flexGrow: 1,
  marginRight: "var(--ds-space-200, 16px)"
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const IssueFormsFooterWrapper = styled.div({
  borderTop: `1px solid ${"var(--ds-border, #dfe1e5)"}`,
  display: 'flex',
  flex: 'none',
  justifyContent: 'center',
  marginBottom: "var(--ds-space-250, 20px)",
  paddingTop: "var(--ds-space-250, 20px)"
});