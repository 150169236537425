/* eslint-disable @typescript-eslint/no-explicit-any, @atlaskit/design-system/ensure-design-token-usage/preview */
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css } from '@emotion/react';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from '@emotion/styled';
import { B200, B400, N0, N800 } from '@atlaskit/theme/colors';
import { h700 } from '@atlaskit/theme/typography';
const illustrationHeight = 64;
const wrapperStyle = css({
  textAlign: 'center',
  '&::before': {
    lineHeight: `${illustrationHeight}px`,
    margin: `${"var(--ds-space-250, 20px)"} 0`,
    fontSize: `${illustrationHeight}px`
  }
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
export const SuccessWrapper = styled.div(wrapperStyle, {
  margin: `0 -${"var(--ds-space-250, 20px)"}`,
  zIndex: 1,
  display: 'flex',
  alignItems: 'center'
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
export const Heading = styled.h1(h700(), {
  color: `var(--ds-text, ${N800})`,
  margin: `${"var(--ds-space-200, 16px)"} 0`,
  fontWeight: 500
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Content = styled.p({
  color: `var(--ds-text, ${N800})`,
  margin: `${"var(--ds-space-200, 16px)"} 0`
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const SubmitAnotherButtonWrapper = styled.div({
  paddingTop: "var(--ds-space-250, 20px)",
  margin: `${"var(--ds-space-100, 8px)"} ${"var(--ds-space-500, 40px)"} 0`
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Rectangle = styled.div({
  background: `linear-gradient( 204.44deg, ${`var(--ds-background-accent-blue-subtle, ${B400})`} 10.89%, ${`var(--ds-background-accent-blue-subtler, ${B200})`} 79.93% )`,
  transform: 'matrix(1, 0, 0, -1, 0, 0)',
  minHeight: '200px',
  height: '300px',
  width: '100%',
  position: 'absolute',
  zIndex: 0
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ResultContainer = styled.div({
  background: `var(--ds-surface-raised, ${N0})`,
  boxShadow: "var(--ds-shadow-raised, 0px 0px 1px rgba(0, 0, 0, 0.31), 0px 1px 1px rgba(0, 0, 0, 0.25))",
  borderRadius: '10px',
  padding: `${"var(--ds-space-800, 64px)"} ${"var(--ds-space-600, 48px)"}`,
  zIndex: 1,
  width: '320px',
  margin: `${"var(--ds-space-1000, 80px)"} auto`
});