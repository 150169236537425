import { defineMessages } from 'react-intl-next';
export const messages = defineMessages({
  choiceDropdownSelect: {
    id: 'proforma-common-core.form-system.components.questions.cascading-dropdown.edit.choice-dropdown-select',
    defaultMessage: 'Select...',
    description: 'Placeholder text in dropdown waiting for the user to select something.'
  },
  choiceDropdownNoOptions: {
    id: 'proforma-common-core.form-system.components.questions.cascading-dropdown.edit.choice-dropdown-no-options',
    defaultMessage: 'No options',
    description: 'Placeholder text in dropdown when there are no options.'
  }
});