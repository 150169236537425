/* prettier-ignore */
/**
 * NOTE:
 *
 * This file is automatically generated by Traduki 2.0.
 * DO NOT CHANGE IT BY HAND or your changes will be lost.
 */
//Spanish (International)
export default {
  'proforma-jira-issue-forms.common.ui.footer.create-another-label': 'Crear otra incidencia',
  'proforma-jira-issue-forms.common.ui.footer.create-button-label': 'Crear',
  'proforma-jira-issue-forms.common.ui.footer.failed-creating-flag-description': 'Inténtalo de nuevo más tarde.',
  'proforma-jira-issue-forms.common.ui.footer.failed-creating-flag-title': 'No hemos podido crear tu incidencia.',
  'proforma-jira-issue-forms.common.ui.footer.failed-creating-with-save-errors-flag-description': 'Corrige las respuestas no válidas y comprueba que todos los campos obligatorios estén completos.',
  'proforma-jira-issue-forms.common.ui.footer.successfully-created-flag-copy-link-action': 'Copiar enlace',
  'proforma-jira-issue-forms.common.ui.footer.successfully-created-flag-title': 'Has creado la incidencia "{issueKey}".',
  'proforma-jira-issue-forms.common.ui.footer.successfully-created-flag-view-issue-action': 'Ver incidencia',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-edit-form-link-label-for-project-admin': 'Editar formulario',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-is-conditional-action-for-project-admin': 'Editar formulario de solicitud',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-is-conditional-description-for-non-project-admin': 'Pide a tu administrador que saque el campo <strong>{formFieldName}</strong> de la sección condicional o que elimine el estado de obligatoriedad de su campo de Jira vinculado <strong>{jiraFieldName}</strong> en el formulario de solicitud <strong>{typeName}</strong>.',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-is-conditional-description-for-project-admin': 'Saca el campo <strong>{formFieldName}</strong> de la sección condicional o elimina el estado de obligatoriedad de su campo de Jira vinculado <strong>{jiraFieldName}</strong> en el formulario de solicitud <strong>{typeName}</strong>.',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-is-conditional-title': 'El campo "Obligatorio" en una sección condicional',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-missing-description-for-non-project-admin': 'Pide a tu administrador que vincule un campo de este formulario con el campo de Jira <strong>{jiraFieldName}</strong> en el formulario de solicitud <strong>{typeName}</strong>.',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-missing-description-for-project-admin': 'Vincula un campo de este formulario con el campo de Jira <strong>{jiraFieldName}</strong> en el formulario de solicitud <strong>{typeName}</strong>.',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-missing-title': 'El campo "Obligatorio" no está vinculado',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-needs-to-be-mandatory-description-for-non-project-admin': 'Pide a tu administrador que marque el campo <strong>{formFieldName}</strong> de este formulario como &quot;Obligatorio&quot; para que coincida con su campo de Jira vinculado <strong>{jiraFieldName}</strong> en el formulario de solicitud <strong>{typeName}</strong>.',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-needs-to-be-mandatory-description-for-project-admin': 'Marca el campo <strong>{formFieldName}</strong> de este formulario como obligatorio para que coincida con su campo de Jira vinculado <strong>{jiraFieldName}</strong> en el formulario de solicitud <strong>{typeName}</strong>.',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-needs-to-be-mandatory-title': 'El campo obligatorio no coincide',
  'proforma-jira-issue-forms.common.utils.process-form-load-error.form-loading-failed-flag-description': 'Actualiza la página e inténtalo de nuevo.',
  'proforma-jira-issue-forms.common.utils.process-form-load-error.form-loading-failed-flag-title': 'No hemos podido cargar tu formulario.',
  'proforma-jira-issue-forms.controllers.form-store.object-search-error-description': 'Elimina los atributos que ya no existan en el filtro de configuración de campo personalizado de Activos y asegúrate de que la consulta de AQL sea válida.',
  'proforma-jira-issue-forms.controllers.form-store.object-search-error-title': 'No hemos podido obtener ningún objeto para este campo',
  'proforma-jira-issue-forms.ui.direct.embed.successfully-created-button-embed': 'Crear otra incidencia',
  'proforma-jira-issue-forms.ui.direct.embed.successfully-created-description-with-link-embed': 'Consulta <a>issueKey</a> para actualizar o gestionar esta incidencia.',
  'proforma-jira-issue-forms.ui.direct.embed.successfully-created-title-embed': 'Hemos creado tu incidencia',
  'proforma-jira-issue-forms.ui.general.direct-link-section-message-embed': 'Crea incidencias con formularios integrados pegando este enlace en cualquier sitio de Atlassian. <a>Copiar enlace</a>',
  'proforma-jira-issue-forms.ui.general.header.error-loading-project-associated-forms-description': 'Actualiza la página e inténtalo de nuevo.',
  'proforma-jira-issue-forms.ui.general.header.error-loading-project-associated-forms-title': 'Tenemos problemas para cargar tus formularios.',
  'proforma-jira-issue-forms.ui.general.header.error-loading-projects-description': 'Actualiza la página e inténtalo de nuevo.',
  'proforma-jira-issue-forms.ui.general.header.error-loading-projects-title': 'Tenemos problemas para cargar tus proyectos.',
  'proforma-jira-issue-forms.ui.general.header.form-placeholder': 'Seleccionar un formulario',
  'proforma-jira-issue-forms.ui.general.header.issue-type-placeholder': 'Seleccionar un tipo de incidencia',
  'proforma-jira-issue-forms.ui.general.header.page-description-embed': 'Crea incidencias con un formulario o directamente desde un enlace a un formulario. <a>Obtén más información sobre cómo crear incidencias con formularios.</a>',
  'proforma-jira-issue-forms.ui.general.header.page-header': 'Crear incidencia',
  'proforma-jira-issue-forms.ui.general.header.project-has-no-associated-forms-jsm': 'Este proyecto de asistencia no tiene formularios asociados.',
  'proforma-jira-issue-forms.ui.general.header.project-has-no-associated-forms-jsw': 'Este proyecto de software no tiene formularios asociados.',
  'proforma-jira-issue-forms.ui.general.header.project-has-no-associated-forms-jwm': 'Este proyecto empresarial no tiene formularios asociados.',
  'proforma-jira-issue-forms.ui.general.header.project-placeholder': 'Seleccionar un proyecto',
  'proforma-jira-issue-forms.ui.general.header.request-type-placeholder': 'Seleccionar un tipo de solicitud'
};