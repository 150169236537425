import { useEffect, useState } from 'react';
import { DEFAULT_LOCALE } from './ProFormaIntlProvider';
const localeRegex = /([a-z]+)(?:[-_]([A-Z]+))?/i;
export const getCodesFromLocale = locale => {
  let match = localeRegex.exec(locale);
  if (!match) {
    // eslint-disable-next-line no-console
    console.error('Unable to get language and country from invalid locale, falling back to default locale.');
    match = localeRegex.exec(DEFAULT_LOCALE);
  }
  const [, language, country] = match;
  return {
    language: language.toLowerCase(),
    country: country === null || country === void 0 ? void 0 : country.toUpperCase()
  };
};
const loadLazyI18nMessages = async (locale, lazyI18nMessages) => {
  var _await$default, _await, _ref;
  const {
    language,
    country
  } = getCodesFromLocale(locale);
  return (_await$default = (_await = await ((_ref = country && lazyI18nMessages[`${language}_${country}`] || lazyI18nMessages[language] || lazyI18nMessages.en) === null || _ref === void 0 ? void 0 : _ref())) === null || _await === void 0 ? void 0 : _await.default) !== null && _await$default !== void 0 ? _await$default : {};
};
export const useLazyI18nMessages = (locale, lazyI18nMessages) => {
  const [messages, setMessages] = useState();
  useEffect(() => {
    loadLazyI18nMessages(locale, lazyI18nMessages).catch(() => ({})).then(setMessages);
  }, [locale, setMessages, lazyI18nMessages]);
  return messages;
};