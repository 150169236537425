const capitaliseFirstLetter = s => s.charAt(0).toUpperCase() + s.slice(1);
export const getFormAnalyticsAttributes = form => {
  var _form$publish, _form$publish2, _jiraConfig$issueRequ, _jiraConfig$issueRequ2, _portalConfig$portalR, _portalConfig$portalR2, _jiraConfig$newIssueI, _jiraConfig$newIssueI2, _jiraConfig$newIssueR, _jiraConfig$newIssueR2;
  const jiraConfig = (_form$publish = form.publish) === null || _form$publish === void 0 ? void 0 : _form$publish.jira;
  const portalConfig = (_form$publish2 = form.publish) === null || _form$publish2 === void 0 ? void 0 : _form$publish2.portal;
  const design = form.design;
  const questions = Object.entries(design.questions);
  const sections = Object.values(design.sections);
  const conditions = Object.values(design.conditions);
  const questionsAttributes = {};
  const questionsRequiredAttributes = {};
  const questionsUsedAsSectionConditionAttributes = {};
  const jiraFieldAttributes = {};
  const dataConnectionsAttributes = {};
  questions.forEach(([id, question]) => {
    var _questionsAttributes$, _questionsRequiredAtt, _questionsUsedAsSecti;
    const questionType = capitaliseFirstLetter(question.type);
    const questionAttribute = `templateFormQuestion${questionType}Count`;
    questionsAttributes[questionAttribute] = ((_questionsAttributes$ = questionsAttributes[questionAttribute]) !== null && _questionsAttributes$ !== void 0 ? _questionsAttributes$ : 0) + 1;
    const questionRequiredAttribute = `templateFormRequiredQuestion${questionType}Count`;
    questionsRequiredAttributes[questionRequiredAttribute] = ((_questionsRequiredAtt = questionsRequiredAttributes[questionRequiredAttribute]) !== null && _questionsRequiredAtt !== void 0 ? _questionsRequiredAtt : 0) + (question.validation.rq ? 1 : 0);
    if (question.jiraField) {
      var _jiraFieldAttributes$;
      const jiraFieldAttribute = `templateFormJiraFieldQuestion${questionType}Count`;
      jiraFieldAttributes[jiraFieldAttribute] = ((_jiraFieldAttributes$ = jiraFieldAttributes[jiraFieldAttribute]) !== null && _jiraFieldAttributes$ !== void 0 ? _jiraFieldAttributes$ : 0) + 1;
    }
    if (question.dcId) {
      var _dataConnectionsAttri;
      const dataConnectionAttribute = `templateFormDataConnectionQuestion${questionType}Count`;
      dataConnectionsAttributes[dataConnectionAttribute] = ((_dataConnectionsAttri = dataConnectionsAttributes[dataConnectionAttribute]) !== null && _dataConnectionsAttri !== void 0 ? _dataConnectionsAttri : 0) + 1;
    }
    const questionUsedAsSectionConditionAttribute = `templateFormUsedAsSectionConditionQuestion${questionType}Count`;
    const questionUsedAsSectionConditionCount = conditions.filter(condition => {
      var _condition$i$co;
      return (_condition$i$co = condition.i.co) === null || _condition$i$co === void 0 ? void 0 : _condition$i$co.cIds[id];
    }).length;
    questionsUsedAsSectionConditionAttributes[questionUsedAsSectionConditionAttribute] = ((_questionsUsedAsSecti = questionsUsedAsSectionConditionAttributes[questionUsedAsSectionConditionAttribute]) !== null && _questionsUsedAsSecti !== void 0 ? _questionsUsedAsSecti : 0) + questionUsedAsSectionConditionCount;
  });
  const layoutItemAttributes = {};
  const recurseLayoutItemAttributes = layoutItemNodes => {
    layoutItemNodes.forEach(layoutItemNode => {
      var _layoutItemAttributes, _layoutItemNode$conte;
      const layoutItemType = capitaliseFirstLetter(layoutItemNode.type);
      const layoutItemName = `templateFormLayoutItem${layoutItemType}Count`;
      layoutItemAttributes[layoutItemName] = ((_layoutItemAttributes = layoutItemAttributes[layoutItemName]) !== null && _layoutItemAttributes !== void 0 ? _layoutItemAttributes : 0) + 1;
      recurseLayoutItemAttributes((_layoutItemNode$conte = layoutItemNode.content) !== null && _layoutItemNode$conte !== void 0 ? _layoutItemNode$conte : []);
    });
  };
  recurseLayoutItemAttributes(design.layout);
  return {
    ...questionsAttributes,
    ...questionsRequiredAttributes,
    ...questionsUsedAsSectionConditionAttributes,
    ...jiraFieldAttributes,
    ...dataConnectionsAttributes,
    ...layoutItemAttributes,
    templateFormQuestionsCount: questions.length,
    templateFormSectionsCount: sections.length,
    templateFormConditionalSectionsCount: sections.filter(section => {
      var _section$conditions;
      return (_section$conditions = section.conditions) === null || _section$conditions === void 0 ? void 0 : _section$conditions.length;
    }).length,
    templateFormConditionsCount: conditions.length,
    templateFormLinkedFieldsCount: questions.filter(([, question]) => question.jiraField).length,
    templateFormDataConnectionFieldsCount: questions.filter(([, question]) => question.dcId).length,
    templateFormRecommendedCount: (_jiraConfig$issueRequ = jiraConfig === null || jiraConfig === void 0 ? void 0 : (_jiraConfig$issueRequ2 = jiraConfig.issueRequestTypeIds) === null || _jiraConfig$issueRequ2 === void 0 ? void 0 : _jiraConfig$issueRequ2.length) !== null && _jiraConfig$issueRequ !== void 0 ? _jiraConfig$issueRequ : 0,
    templateFormPortalFormsCount: (_portalConfig$portalR = portalConfig === null || portalConfig === void 0 ? void 0 : (_portalConfig$portalR2 = portalConfig.portalRequestTypeIds) === null || _portalConfig$portalR2 === void 0 ? void 0 : _portalConfig$portalR2.length) !== null && _portalConfig$portalR !== void 0 ? _portalConfig$portalR : 0,
    templateFormIssueFormsCount: ((_jiraConfig$newIssueI = jiraConfig === null || jiraConfig === void 0 ? void 0 : (_jiraConfig$newIssueI2 = jiraConfig.newIssueIssueTypeIds) === null || _jiraConfig$newIssueI2 === void 0 ? void 0 : _jiraConfig$newIssueI2.length) !== null && _jiraConfig$newIssueI !== void 0 ? _jiraConfig$newIssueI : 0) + ((_jiraConfig$newIssueR = jiraConfig === null || jiraConfig === void 0 ? void 0 : (_jiraConfig$newIssueR2 = jiraConfig.newIssueRequestTypeIds) === null || _jiraConfig$newIssueR2 === void 0 ? void 0 : _jiraConfig$newIssueR2.length) !== null && _jiraConfig$newIssueR !== void 0 ? _jiraConfig$newIssueR : 0),
    isTemplateFormSubmitOnRequestCreate: (portalConfig === null || portalConfig === void 0 ? void 0 : portalConfig.submitOnCreate) || false,
    isTemplateFormValidateOnRequestCreate: (portalConfig === null || portalConfig === void 0 ? void 0 : portalConfig.submitOnCreate) || (portalConfig === null || portalConfig === void 0 ? void 0 : portalConfig.validateOnCreate) || false,
    isTemplateFormGeneratePDFOnSubmit: design.settings.submit.pdf,
    isTemplateFormLockOnSubmit: design.settings.submit.lock
  };
};
export const getTemplateFormAnalyticsAttributes = (updatedTemplateForm, oldTemplateForm) => {
  var _oldTemplateForm$publ, _oldTemplateForm$publ2, _oldTemplateForm$publ3, _updatedTemplateForm$, _updatedTemplateForm$2, _updatedTemplateForm$3;
  const updatedFormAttributes = getFormAnalyticsAttributes(updatedTemplateForm);
  const oldFormAttributes = getFormAnalyticsAttributes(oldTemplateForm);
  const deltaAttributes = {};
  const toggleAttributes = {};
  function addDeltaAttribute(numberAttributeKey) {
    var _ref, _ref2;
    const oldValue = (_ref = oldFormAttributes[numberAttributeKey]) !== null && _ref !== void 0 ? _ref : 0;
    const newValue = (_ref2 = updatedFormAttributes[numberAttributeKey]) !== null && _ref2 !== void 0 ? _ref2 : 0;
    deltaAttributes[`${numberAttributeKey}Delta`] = newValue - oldValue;
  }
  function addToggleAttribute(booleanAttributeKey) {
    const oldValue = oldFormAttributes[booleanAttributeKey];
    const newValue = updatedFormAttributes[booleanAttributeKey];
    if (oldValue && !newValue) {
      toggleAttributes[`${booleanAttributeKey}Toggled`] = 'off';
    } else if (!oldValue && newValue) {
      toggleAttributes[`${booleanAttributeKey}Toggled`] = 'on';
    }
  }
  Object.entries(updatedFormAttributes).forEach(([key, value]) => {
    if (typeof value === 'number') {
      addDeltaAttribute(key);
    } else if (typeof value === 'boolean') {
      addToggleAttribute(key);
    }
  });

  /**
   * Cover attributes that are undefined in `updatedFormAttributes`
   *
   * E.G.
   *   When user removed all 4 ChoiceDropdown questions, it would be
   *   oldFormAttributes = { templateFormQuestionCdCount: 4, ...rest };
   *   updatedFormAttributes = { templateFormQuestionCdCount: never, ...rest };
   *  */
  Object.entries(oldFormAttributes).forEach(([key, value]) => {
    if (typeof value === 'number' && deltaAttributes[`${key}Delta`] === undefined) {
      addDeltaAttribute(key);
    } else if (typeof value === 'boolean' && deltaAttributes[`${key}Toggled`] === undefined) {
      addToggleAttribute(key);
    }
  });
  const oldPortalForms = (_oldTemplateForm$publ = (_oldTemplateForm$publ2 = oldTemplateForm.publish) === null || _oldTemplateForm$publ2 === void 0 ? void 0 : (_oldTemplateForm$publ3 = _oldTemplateForm$publ2.portal) === null || _oldTemplateForm$publ3 === void 0 ? void 0 : _oldTemplateForm$publ3.portalRequestTypeIds) !== null && _oldTemplateForm$publ !== void 0 ? _oldTemplateForm$publ : [];
  const newPortalForms = (_updatedTemplateForm$ = (_updatedTemplateForm$2 = updatedTemplateForm.publish) === null || _updatedTemplateForm$2 === void 0 ? void 0 : (_updatedTemplateForm$3 = _updatedTemplateForm$2.portal) === null || _updatedTemplateForm$3 === void 0 ? void 0 : _updatedTemplateForm$3.portalRequestTypeIds) !== null && _updatedTemplateForm$ !== void 0 ? _updatedTemplateForm$ : [];
  const templateFormPortalFormAdded = newPortalForms.filter(n => !oldPortalForms.includes(n)).length;
  const templateFormPortalFormRemoved = oldPortalForms.filter(n => !newPortalForms.includes(n)).length;
  return {
    ...updatedFormAttributes,
    ...deltaAttributes,
    ...toggleAttributes,
    templateFormPortalFormAdded,
    templateFormPortalFormRemoved
  };
};