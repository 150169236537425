export const ApiFieldDataResponseSchema = {
  $schema: 'http://json-schema.org/draft-04/schema#',
  description: 'Schema for the response of API fielddata.',
  $ref: '#/definitions/fielddata',
  definitions: {
    fielddata: {
      type: 'object',
      patternProperties: {
        '^[a-zA-Z0-9{}/]+$': {
          $ref: '#/definitions/issuefielddata'
        }
      }
    },
    issuefielddata: {
      properties: {
        value: {
          $ref: '#/definitions/issuefieldvalue'
        },
        readOnlyReason: {
          type: 'string'
        },
        maxAllowedChoices: {
          type: 'number'
        },
        multiple: {
          type: 'boolean'
        },
        fieldConfigId: {
          type: 'string'
        },
        populateDefaultValueOnEmptySearch: {
          type: 'boolean'
        },
        dependsOn: {
          type: 'array',
          items: {
            type: 'string'
          }
        },
        schema: {
          $ref: '#/definitions/schema'
        }
      },
      required: ['value'],
      type: 'object'
    },
    schema: {
      properties: {
        type: {
          type: 'string'
        },
        custom: {
          type: 'string'
        },
        system: {
          type: 'string'
        }
      },
      type: 'object'
    },
    issuefieldvalue: {
      properties: {
        choices: {
          type: 'array',
          items: {
            $ref: '#/definitions/jirafieldchoice'
          }
        },
        date: {
          type: 'string'
        },
        text: {
          type: 'string'
        },
        time: {
          type: 'string'
        },
        users: {
          items: {
            $ref: '#/definitions/userpickeruser'
          },
          type: 'array'
        }
      },
      type: 'object'
    },
    jirafieldchoice: {
      defaultProperties: [],
      properties: {
        id: {
          type: 'string'
        },
        label: {
          type: 'string'
        }
      },
      required: ['id', 'label'],
      type: 'object'
    },
    userpickeruser: {
      defaultProperties: [],
      properties: {
        id: {
          type: 'string'
        },
        name: {
          type: 'string'
        }
      },
      required: ['id', 'name'],
      type: 'object'
    }
  }
};