import { defineMessages } from 'react-intl-next';
export const messages = defineMessages({
  choiceDropdownSelect: {
    id: 'form-system.choice-dropdown.select',
    defaultMessage: 'Select...',
    description: 'Placeholder text in dropdown waiting for the user to select something.'
  },
  choiceDropdownLoading: {
    id: 'form-system.choice-dropdown.loading',
    defaultMessage: 'Loading...',
    description: 'Placeholder text in dropdown while loading.'
  },
  choiceDropdownNoOptions: {
    id: 'form-system.choice-dropdown.no-options',
    defaultMessage: 'No options',
    description: 'Placeholder text in dropdown when there are no options.'
  },
  choiceDropdownNewOption: {
    id: 'form-system.choice-dropdown.new-option',
    defaultMessage: 'New option...',
    description: 'Placeholder text in dropdown when a new option can be created.'
  }
});