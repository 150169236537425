import { defineMessages } from 'react-intl-next';
export const messages = defineMessages({
  // ---------- ---------- ---------- ---------- ---------- ---------- ---------- ---------- ---------- ----------
  // NOTE: These should be kept in-sync with Issue Forms Direct:
  // ---------- ---------- ---------- ---------- ---------- ---------- ---------- ---------- ---------- ----------
  conflictEditFormLinkLabelForProjectAdmin: {
    id: 'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-edit-form-link-label-for-project-admin',
    defaultMessage: 'Edit form',
    description: 'The Issue Forms conflict edit form link label for a project admin.'
  },
  conflictIsConditionalActionForProjectAdmin: {
    id: 'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-is-conditional-action-for-project-admin',
    defaultMessage: 'Edit request form',
    description: 'The Issue Forms conflict edit type link label for a project admin.'
  },
  conflictIsConditionalDescriptionForNonProjectAdmin: {
    id: 'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-is-conditional-description-for-non-project-admin',
    defaultMessage: 'Ask your admin to move the <strong>{formFieldName}</strong> field out of the conditional section, or remove the ‘required’ status from its linked <strong>{jiraFieldName}</strong> Jira field on the <strong>{typeName}</strong> request form.',
    description: 'The description when we need to display a conflict for a conditionally displayed required Jira field in Issue Forms.'
  },
  conflictIsConditionalDescriptionForProjectAdmin: {
    id: 'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-is-conditional-description-for-project-admin',
    defaultMessage: 'Move the <strong>{formFieldName}</strong> field out of the conditional section, or remove the ‘required’ status from its linked <strong>{jiraFieldName}</strong> Jira field on the <strong>{typeName}</strong> request form.',
    description: 'The description when we need to display a conflict for a conditionally displayed required Jira field in Issue Forms.'
  },
  conflictIsConditionalTitle: {
    id: 'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-is-conditional-title',
    defaultMessage: '‘Required’ field in conditional section',
    description: 'The title when we need to display a conflict for a conditionally displayed required Jira field in Issue Forms.'
  },
  conflictMissingDescriptionForNonProjectAdmin: {
    id: 'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-missing-description-for-non-project-admin',
    defaultMessage: 'Ask your admin to link a field in this form with the <strong>{jiraFieldName}</strong> Jira field in the <strong>{typeName}</strong> request form.',
    description: 'The description when we need to display a conflict for a missing required Jira field in Issue Forms.'
  },
  conflictMissingDescriptionForProjectAdmin: {
    id: 'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-missing-description-for-project-admin',
    defaultMessage: 'Link a field in this form with the <strong>{jiraFieldName}</strong> Jira field in the <strong>{typeName}</strong> request form.',
    description: 'The description when we need to display a conflict for a missing required Jira field in Issue Forms.'
  },
  conflictMissingTitle: {
    id: 'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-missing-title',
    defaultMessage: '‘Required’ field is not linked',
    description: 'The title when we need to display a conflict for a missing required Jira field in Issue Forms.'
  },
  conflictNeedsToBeMandatoryDescriptionForNonProjectAdmin: {
    id: 'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-needs-to-be-mandatory-description-for-non-project-admin',
    defaultMessage: 'Ask your admin to mark the <strong>{formFieldName}</strong> field in this form as ‘required’, to match its linked <strong>{jiraFieldName}</strong> Jira field in the <strong>{typeName}</strong> request form. ',
    description: 'The description when we need to display a conflict for a required Jira field that needs to be mandatory in Issue Forms.'
  },
  conflictNeedsToBeMandatoryDescriptionForProjectAdmin: {
    id: 'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-needs-to-be-mandatory-description-for-project-admin',
    defaultMessage: 'Mark the <strong>{formFieldName}</strong> field in this form as ‘required’, to match its linked <strong>{jiraFieldName}</strong> Jira field in the <strong>{typeName}</strong> request form. ',
    description: 'The description when we need to display a conflict for a required Jira field that needs to be mandatory in Issue Forms.'
  },
  conflictNeedsToBeMandatoryTitle: {
    id: 'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-needs-to-be-mandatory-title',
    defaultMessage: '‘Required’ field does not match',
    description: 'The title when we need to display a conflict for a required Jira field that needs to be mandatory in Issue Forms.'
  }
});