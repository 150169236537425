import { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl-next';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { useFlags } from '@atlaskit/flag';
import { useFormSaveErrorsActions } from '@atlassian/proforma-common-core/form-system';
import { FormStore } from '@atlassian/proforma-common-core/form-system-stores';
import { AFFECTED_SERVICES_SCHEMA_CUSTOM, CMDB_SCHEMA_CUSTOM, getCmdbFieldValueMap, LABELS_SCHEMA_CUSTOM, LABELS_SCHEMA_SYSTEM } from '@atlassian/proforma-common-core/form-system-utils';
import { usePfErrorUtils } from '@atlassian/proforma-common-core/jira-common-context';
import { loadChoicesAsyncFn, loadChoicesFn, loadFieldDataFn } from '@atlassian/proforma-common-core/jira-common-stores';
import { useConvertServiceResponseToPromise } from '@atlassian/proforma-common-core/jira-common-utils';
import { useAffectedServicesChoicesService } from '../../services/affected-services';
import { useCmdbChoicesService } from '../../services/cmdb';
import { useFieldDataService } from '../../services/field-data';
import { useFormChoicesService } from '../../services/form-choices';
import { useLabelChoicesService } from '../../services/label-choices';
import { useUserChoicesService } from '../../services/user-choices';
import { messages } from './messages';
export const useFormStoreController = reporterAccountId => {
  const {
    createAnalyticsEvent
  } = useAnalyticsEvents();
  const {
    clear: clearFormSaveErrors
  } = useFormSaveErrorsActions();
  const [{
    data: fieldData,
    error: fieldDataError,
    loading: fieldDataLoading
  }, {
    load: loadFieldData
  }] = useFieldDataService();
  const [{
    data: formChoices,
    error: formChoicesError,
    loading: formChoicesLoading
  }, {
    load: loadFormChoices
  }] = useFormChoicesService();
  const intl = useIntl();
  const {
    showFlag
  } = useFlags();
  const [{
    data: labelChoices,
    error: labelChoicesError,
    loading: labelChoicesLoading
  }, {
    load: loadLabelChoices
  }] = useLabelChoicesService();
  const errorUtils = usePfErrorUtils();
  const [{
    data: userChoices,
    error: userChoicesError,
    loading: userChoicesLoading
  }, {
    load: loadUserChoices,
    loadWithFieldConfig: loadUserChoicesWithFieldConfig
  }] = useUserChoicesService();
  const [{
    data: affectedServicesChoices,
    error: affectedServicesChoicesError,
    loading: affectedServicesChoicesLoading
  }, {
    load: loadAffectedServicesChoices
  }] = useAffectedServicesChoicesService();
  const [{
    data: cmdbChoices,
    error: cmdbChoicesError,
    loading: cmdbChoicesLoading
  }, {
    load: loadCmdbChoices
  }] = useCmdbChoicesService();
  const createFieldDataPromise = useConvertServiceResponseToPromise(fieldData, fieldDataError);
  const createFormChoicesPromise = useConvertServiceResponseToPromise(formChoices, formChoicesError);
  const createLabelChoicesPromise = useConvertServiceResponseToPromise(labelChoices, labelChoicesError);
  const createUserChoicesPromise = useConvertServiceResponseToPromise(userChoices, userChoicesError);
  const createAffectedServicesChoicesPromise = useConvertServiceResponseToPromise(affectedServicesChoices, affectedServicesChoicesError);
  const createCmdbChoicesPromise = useConvertServiceResponseToPromise(cmdbChoices, cmdbChoicesError);
  const [state, setState] = useState({});
  const actions = useMemo(() => ({
    clear: () => {
      setState({});
      clearFormSaveErrors();
    },
    create: (formDetails, form) => {
      setState({
        formStore: new FormStore(intl, createAnalyticsEvent, loadFieldDataFn(request => {
          if (fieldDataLoading) {
            return Promise.reject(new Error('Field data request already underway.'));
          }
          loadFieldData(formDetails, request);
          return createFieldDataPromise();
        }, errorUtils, false), loadChoicesFn(() => {
          if (formChoicesLoading) {
            return Promise.reject(new Error('Form choices request already underway.'));
          }
          loadFormChoices(formDetails);
          return createFormChoicesPromise();
        }, errorUtils), loadChoicesAsyncFn({
          custom: {
            [AFFECTED_SERVICES_SCHEMA_CUSTOM]: {
              searchOptionsFn: (_, query) => {
                if (affectedServicesChoicesLoading) {
                  return Promise.reject(new Error('Affected Services choices loading underway.'));
                }
                loadAffectedServicesChoices(query);
                return createAffectedServicesChoicesPromise();
              }
            },
            [CMDB_SCHEMA_CUSTOM]: {
              searchOptionsFn: (jiraField, query, issueFieldData, formStore) => {
                if (cmdbChoicesLoading) {
                  return Promise.reject(new Error('CMDB choices request already underway.'));
                }
                if (issueFieldData !== null && issueFieldData !== void 0 && issueFieldData.fieldConfigId && formStore) {
                  var _formDetails$requestT;
                  loadCmdbChoices(jiraField, query, formDetails.projectId.toString(), formDetails.issueType.id, (_formDetails$requestT = formDetails.requestType) === null || _formDetails$requestT === void 0 ? void 0 : _formDetails$requestT.id, formDetails.projectFormId, issueFieldData === null || issueFieldData === void 0 ? void 0 : issueFieldData.fieldConfigId, getCmdbFieldValueMap(formStore));
                  return createCmdbChoicesPromise();
                }
                return Promise.resolve([]);
              },
              onSearchError: ({
                status
              }) => {
                if (status === 400) {
                  return {
                    title: intl.formatMessage(messages.objectSearchErrorTitle),
                    description: intl.formatMessage(messages.objectSearchErrorDescription)
                  };
                }
              }
            },
            [LABELS_SCHEMA_CUSTOM]: {
              searchOptionsFn: (jiraField, query) => {
                if (labelChoicesLoading) {
                  return Promise.reject(new Error('Label choices request already underway.'));
                }
                if (!query.includes(' ')) {
                  loadLabelChoices(jiraField, query);
                  return createLabelChoicesPromise();
                }
                return Promise.resolve([]);
              }
            }
          },
          system: {
            [LABELS_SCHEMA_SYSTEM]: {
              searchOptionsFn: (jiraField, query) => {
                if (labelChoicesLoading) {
                  return Promise.reject(new Error('Label choices request already underway.'));
                }
                if (!query.includes(' ')) {
                  loadLabelChoices(jiraField, query);
                  return createLabelChoicesPromise();
                }
                return Promise.resolve([]);
              }
            }
          }
        }, errorUtils, createAnalyticsEvent, showFlag), (jiraField, query) => {
          if (userChoicesLoading) {
            return Promise.reject(new Error('User search request already underway.'));
          }
          loadUserChoices(formDetails.projectId, jiraField, query);
          return createUserChoicesPromise();
        }, (jiraField, fieldConfigId, query) => {
          if (userChoicesLoading) {
            return Promise.reject(new Error('User search request already underway.'));
          }
          loadUserChoicesWithFieldConfig(jiraField, fieldConfigId, formDetails.projectId, query);
          return createUserChoicesPromise();
        }, form, false)
      });
      clearFormSaveErrors();
    }
  }),
  // NOTE: If `*Loading` is included, then this is constantly regenerated, triggering endless backend requests.
  // NOTE: `intl` from `useIntl()` must not be used in a dependency list.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [clearFormSaveErrors, createAnalyticsEvent, createAffectedServicesChoicesPromise, createCmdbChoicesPromise, createFieldDataPromise, createFormChoicesPromise, createLabelChoicesPromise, createUserChoicesPromise, errorUtils, loadAffectedServicesChoices, loadCmdbChoices, loadFieldData, loadFormChoices, loadLabelChoices, loadUserChoices]);
  useEffect(() => {
    if (state.formStore) {
      state.formStore.onFieldChange('reporter');
    }
  }, [reporterAccountId, state.formStore]);
  return [state, actions];
};