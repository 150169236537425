import { createContainer, createHook, createStore } from 'react-sweet-state';
import { convertTemplateFormToUnsavedForm } from '@atlassian/proforma-common-core/form-system-utils';
import { urlParam } from '@atlassian/proforma-common-core/jira-common-utils';
const initialState = {
  data: undefined,
  error: undefined,
  loading: false
};
const actions = {
  load: formDetails => ({
    getState,
    setState
  }, {
    apiUtil
  }) => {
    if (!getState().loading) {
      var _formDetails$requestT;
      setState({
        data: undefined,
        error: undefined,
        loading: true
      });
      apiUtil.get(`/api/cloud/createissue/form/${formDetails.projectId}/${formDetails.projectFormId}/issuetype/${formDetails.issueType.id}?${urlParam('requestTypeId', (_formDetails$requestT = formDetails.requestType) === null || _formDetails$requestT === void 0 ? void 0 : _formDetails$requestT.id, '')}`).then(response => {
        setState({
          data: convertTemplateFormToUnsavedForm(response),
          loading: false
        });
      }).catch(apiError => {
        setState({
          error: apiError,
          loading: false
        });
      });
    }
  },
  reset: () => ({
    setState
  }) => {
    setState(initialState);
  }
};
const Store = createStore({
  initialState,
  actions,
  name: 'FormService'
});
export const FormServiceContainer = createContainer(Store);
export const useFormService = createHook(Store);