import { useEffect, useState } from 'react';
export const DEFAULT_LOCALE = 'en';
const localeRegex = /([a-z]+)(?:[-_]([A-Z]+))?/i;
export const getCodesFromLocale = locale => {
  const match = localeRegex.exec(locale);
  if (match) {
    const [, language, country] = match;
    return {
      language: language.toLowerCase(),
      country: country === null || country === void 0 ? void 0 : country.toUpperCase()
    };
  }
  // TODO: Use error service to log:
  // eslint-disable-next-line no-console
  console.error('Unable to determine language and country from invalid locale.');
  return undefined;
};
const loadLazyMessages = async (lazyMessages, language, country) => {
  var _await$default, _await, _ref;
  return (_await$default = (_await = await ((_ref = country && lazyMessages[`${language}_${country}`] || lazyMessages[language] || lazyMessages.en) === null || _ref === void 0 ? void 0 : _ref())) === null || _await === void 0 ? void 0 : _await.default) !== null && _await$default !== void 0 ? _await$default : {};
};
export const useLazyMessages = (lazyMessages, language, country) => {
  const [messages, setMessages] = useState();
  useEffect(() => {
    loadLazyMessages(lazyMessages, language, country).catch(() => ({})).then(setMessages);
  }, [country, language, lazyMessages, setMessages]);
  return messages;
};