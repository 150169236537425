/* prettier-ignore */
/**
 * NOTE:
 *
 * This file is automatically generated by Traduki 2.0.
 * DO NOT CHANGE IT BY HAND or your changes will be lost.
 */
//Chinese (Simplified)
export default {
  'proforma-jira-issue-forms.common.ui.footer.create-another-label': '创建其他事务',
  'proforma-jira-issue-forms.common.ui.footer.create-button-label': '创建',
  'proforma-jira-issue-forms.common.ui.footer.failed-creating-flag-description': '请稍后重试。',
  'proforma-jira-issue-forms.common.ui.footer.failed-creating-flag-title': '无法创建您的事务。',
  'proforma-jira-issue-forms.common.ui.footer.failed-creating-with-save-errors-flag-description': '请处理所有无效答案，并确保已填写所有必填字段。',
  'proforma-jira-issue-forms.common.ui.footer.successfully-created-flag-copy-link-action': '复制链接',
  'proforma-jira-issue-forms.common.ui.footer.successfully-created-flag-title': '您已创建 "{issueKey}" 事务。',
  'proforma-jira-issue-forms.common.ui.footer.successfully-created-flag-view-issue-action': '查看事务',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-edit-form-link-label-for-project-admin': '编辑表单',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-is-conditional-action-for-project-admin': '编辑请求表单',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-is-conditional-description-for-non-project-admin': '请管理员将 &quot;<strong>{formFieldName}</strong>&quot; 字段从条件部分移除，或在 &quot;<strong>{typeName}</strong>&quot; 门户表单上，从与此字段链接的 &quot;<strong>{jiraFieldName}</strong>&quot; Jira 字段中移除“必填”状态。',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-is-conditional-description-for-project-admin': '将 &quot;<strong>{formFieldName}</strong>&quot; 字段从条件部分移除，或在 &quot;<strong>{typeName}</strong>&quot; 请求表单上，从与此字段链接的 &quot;<strong>{jiraFieldName}</strong>&quot; Jira 字段中移除“必填”状态。',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-is-conditional-title': '条件部分中的“必填”字段',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-missing-description-for-non-project-admin': '请管理员将此表单中的字段与 &quot;<strong>{typeName}</strong>&quot; 请求表单中的 &quot;<strong>{jiraFieldName}</strong>&quot; Jira 字段链接起来。',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-missing-description-for-project-admin': '将此表单中的字段与<strong>{typeName}</strong>请求表单中的<strong>{jiraFieldName}</strong> Jira 字段链接起来。',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-missing-title': '“必填”字段未链接',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-needs-to-be-mandatory-description-for-non-project-admin': '请管理员将此表单中的 &quot;<strong>{formFieldName}</strong>&quot; 字段标记为“必填”，以匹配 &quot;<strong>{typeName}</strong>&quot; 请求表单中与此字段链接的 &quot;<strong>{jiraFieldName}</strong>&quot; Jira 字段。',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-needs-to-be-mandatory-description-for-project-admin': '将此表单中的 &quot;<strong>{formFieldName}</strong>&quot; 字段标记为“必填”，以匹配 &quot;<strong>{typeName}</strong>&quot; 请求表单中与此字段链接的 &quot;<strong>{jiraFieldName}</strong>&quot; Jira 字段。',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-needs-to-be-mandatory-title': '“必填”字段不匹配',
  'proforma-jira-issue-forms.common.utils.process-form-load-error.form-loading-failed-flag-description': '请刷新页面，然后重试。',
  'proforma-jira-issue-forms.common.utils.process-form-load-error.form-loading-failed-flag-title': '无法加载您的表单',
  'proforma-jira-issue-forms.controllers.form-store.object-search-error-description': '请移除资产自定义字段配置筛选器中不复存在的属性，并确保 AQL 查询有效。',
  'proforma-jira-issue-forms.controllers.form-store.object-search-error-title': '无法为此字段获取任何对象',
  'proforma-jira-issue-forms.ui.direct.embed.successfully-created-button-embed': '创建其他事务',
  'proforma-jira-issue-forms.ui.direct.embed.successfully-created-description-with-link-embed': '查看 <a>issueKey</a> 以更新或管理此事务。',
  'proforma-jira-issue-forms.ui.direct.embed.successfully-created-title-embed': '我们已创建您的事务',
  'proforma-jira-issue-forms.ui.general.direct-link-section-message-embed': '将此链接粘贴到任意 Atlassian 站点上，从而使用嵌入式表单创建事务。<a>复制链接</a>',
  'proforma-jira-issue-forms.ui.general.header.error-loading-project-associated-forms-description': '请刷新页面，然后重试。',
  'proforma-jira-issue-forms.ui.general.header.error-loading-project-associated-forms-title': '加载您的表单时出错',
  'proforma-jira-issue-forms.ui.general.header.error-loading-projects-description': '请刷新页面，然后重试。',
  'proforma-jira-issue-forms.ui.general.header.error-loading-projects-title': '加载您的项目时出错',
  'proforma-jira-issue-forms.ui.general.header.form-placeholder': '选择表单',
  'proforma-jira-issue-forms.ui.general.header.issue-type-placeholder': '选择事务类型',
  'proforma-jira-issue-forms.ui.general.header.page-description-embed': '使用表单或直接通过表单链接创建事务。<a>了解有关使用表单创建事务的更多信息</a>。',
  'proforma-jira-issue-forms.ui.general.header.page-header': '创建事务',
  'proforma-jira-issue-forms.ui.general.header.project-has-no-associated-forms-jsm': '此服务项目没有任何关联的表单。',
  'proforma-jira-issue-forms.ui.general.header.project-has-no-associated-forms-jsw': '此软件项目没有任何关联的表单。',
  'proforma-jira-issue-forms.ui.general.header.project-has-no-associated-forms-jwm': '此业务项目没有任何关联的表单。',
  'proforma-jira-issue-forms.ui.general.header.project-placeholder': '选择项目',
  'proforma-jira-issue-forms.ui.general.header.request-type-placeholder': '选择请求类型'
};