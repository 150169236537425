import { useEffect, useMemo, useState } from 'react';
export const useConvertServiceResponseToPromise = (data, error) => {
  const [promiseState, setPromiseState] = useState();
  useEffect(() => {
    if (data && promiseState) {
      promiseState.resolve(data);
      setPromiseState(undefined);
    }
  }, [data, promiseState]);
  useEffect(() => {
    if (error && promiseState) {
      promiseState.reject(error);
      setPromiseState(undefined);
    }
  }, [error, promiseState]);
  const createPromise = useMemo(() => () => new Promise((resolve, reject) => {
    setPromiseState({
      resolve,
      reject
    });
  }), []);
  return createPromise;
};