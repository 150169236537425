/* prettier-ignore */
/**
 * NOTE:
 *
 * This file is automatically generated by Traduki 2.0.
 * DO NOT CHANGE IT BY HAND or your changes will be lost.
 */
//French (France)
export default {
  'proforma-jira-issue-forms.common.ui.footer.create-another-label': 'Créer un autre ticket',
  'proforma-jira-issue-forms.common.ui.footer.create-button-label': 'Créer',
  'proforma-jira-issue-forms.common.ui.footer.failed-creating-flag-description': 'Veuillez réessayer ultérieurement.',
  'proforma-jira-issue-forms.common.ui.footer.failed-creating-flag-title': 'Impossible de créer votre ticket.',
  'proforma-jira-issue-forms.common.ui.footer.failed-creating-with-save-errors-flag-description': 'Résolvez les réponses non valides et vérifiez que tous les champs obligatoires sont renseignés.',
  'proforma-jira-issue-forms.common.ui.footer.successfully-created-flag-copy-link-action': 'Copier le lien',
  'proforma-jira-issue-forms.common.ui.footer.successfully-created-flag-title': 'Vous avez créé le ticket « {issueKey} ».',
  'proforma-jira-issue-forms.common.ui.footer.successfully-created-flag-view-issue-action': 'Afficher le ticket',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-edit-form-link-label-for-project-admin': 'Modifier le formulaire',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-is-conditional-action-for-project-admin': 'Modifier le formulaire de la demande',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-is-conditional-description-for-non-project-admin': "Demandez à votre administrateur de déplacer le champ <strong>{formFieldName}</strong> hors de la section conditionnelle ou de supprimer l'état « obligatoire » du champ Jira <strong>{jiraFieldName}</strong> lié sur le formulaire de la demande <strong>{typeName}</strong>.",
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-is-conditional-description-for-project-admin': "Déplacez le champ <strong>{formFieldName}</strong> hors de la section conditionnelle ou supprimez l'état « obligatoire » du champ Jira <strong>{jiraFieldName}</strong> lié sur le formulaire de la demande <strong>{typeName}</strong>.",
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-is-conditional-title': 'Champ « obligatoire » dans la section conditionnelle',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-missing-description-for-non-project-admin': 'Demandez à votre administrateur de lier un champ de ce formulaire au champ Jira <strong>{jiraFieldName}</strong> dans le formulaire de la demande <strong>{typeName}</strong>.',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-missing-description-for-project-admin': 'Liez un champ de ce formulaire au champ Jira <strong>{jiraFieldName}</strong> dans le formulaire de la demande <strong>{typeName}</strong>.',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-missing-title': "Le champ « obligatoire » n'est pas lié",
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-needs-to-be-mandatory-description-for-non-project-admin': "Demandez à votre administrateur de marquer le champ <strong>{formFieldName}</strong> dans ce formulaire comme « obligatoire » pour qu'il corresponde au champ Jira <strong>{jiraFieldName}</strong> lié dans le formulaire de la demande <strong>{typeName}</strong>.",
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-needs-to-be-mandatory-description-for-project-admin': "Marquez le champ <strong>{formFieldName}</strong> dans ce formulaire comme « obligatoire » pour qu'il corresponde au champ Jira <strong>{jiraFieldName}</strong> lié dans le formulaire de la demande <strong>{typeName}</strong>.",
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-needs-to-be-mandatory-title': 'Le champ « obligatoire » ne correspond pas',
  'proforma-jira-issue-forms.common.utils.process-form-load-error.form-loading-failed-flag-description': 'Actualisez la page, puis réessayez.',
  'proforma-jira-issue-forms.common.utils.process-form-load-error.form-loading-failed-flag-title': 'Impossible de charger votre formulaire',
  'proforma-jira-issue-forms.controllers.form-store.object-search-error-description': "Supprimez les attributs qui n'existent plus dans le filtre de configuration de champ personnalisé Actifs et assurez-vous que la requête AQL est valide.",
  'proforma-jira-issue-forms.controllers.form-store.object-search-error-title': "Nous n'avons trouvé aucun objet pour ce champ",
  'proforma-jira-issue-forms.ui.direct.embed.successfully-created-button-embed': 'Créer un autre ticket',
  'proforma-jira-issue-forms.ui.direct.embed.successfully-created-description-with-link-embed': 'Affichez la clé de ticket <a>issueKey</a> pour mettre à jour ou gérer ce ticket.',
  'proforma-jira-issue-forms.ui.direct.embed.successfully-created-title-embed': 'Nous avons créé votre ticket',
  'proforma-jira-issue-forms.ui.general.direct-link-section-message-embed': "Créez des tickets à l'aide de formulaires intégrés en collant ce lien sur n'importe quel site Atlassian. <a>Copier le lien</a>",
  'proforma-jira-issue-forms.ui.general.header.error-loading-project-associated-forms-description': 'Actualisez la page, puis réessayez.',
  'proforma-jira-issue-forms.ui.general.header.error-loading-project-associated-forms-title': 'Nous rencontrons des difficultés pour charger vos formulaires',
  'proforma-jira-issue-forms.ui.general.header.error-loading-projects-description': 'Actualisez la page, puis réessayez.',
  'proforma-jira-issue-forms.ui.general.header.error-loading-projects-title': 'Nous rencontrons des difficultés pour charger vos projets',
  'proforma-jira-issue-forms.ui.general.header.form-placeholder': 'Sélectionner un formulaire',
  'proforma-jira-issue-forms.ui.general.header.issue-type-placeholder': 'Sélectionner un type de ticket',
  'proforma-jira-issue-forms.ui.general.header.page-description-embed': "Créez des tickets à l'aide d'un formulaire ou directement à partir d'un lien vers un formulaire. <a>En savoir plus sur l'utilisation des formulaires pour créer des tickets</a>.",
  'proforma-jira-issue-forms.ui.general.header.page-header': 'Créer un ticket',
  'proforma-jira-issue-forms.ui.general.header.project-has-no-associated-forms-jsm': "Ce projet de services n'est associé à aucun formulaire.",
  'proforma-jira-issue-forms.ui.general.header.project-has-no-associated-forms-jsw': "Ce projet logiciel n'est associé à aucun formulaire.",
  'proforma-jira-issue-forms.ui.general.header.project-has-no-associated-forms-jwm': "Ce projet métier n'est associé à aucun formulaire.",
  'proforma-jira-issue-forms.ui.general.header.project-placeholder': 'Sélectionner un projet',
  'proforma-jira-issue-forms.ui.general.header.request-type-placeholder': 'Sélectionner un type de demande'
};