import Ajv from 'ajv';
import { ApiFieldDataResponseSchema } from './ApiFieldDataResponse.schema';
import { ApiFormChoicesResponseSchema } from './ApiFormChoicesResponse.schema';
const ajv = new Ajv({
  allErrors: true,
  coerceTypes: false,
  format: 'fast',
  nullable: true,
  schemaId: 'auto',
  unicode: true,
  uniqueItems: true,
  useDefaults: true
}).addMetaSchema(require('ajv/lib/refs/json-schema-draft-04.json'));
const schemas = {
  ApiFieldDataResponseSchema,
  ApiFormChoicesResponseSchema
};
export const validate = (schema, data) => {
  var _ajv$errors;
  const schemaInUse = schemas[schema];
  const isValid = !!ajv.validate(schemaInUse, data);
  return {
    isValid,
    errors: (_ajv$errors = ajv.errors) !== null && _ajv$errors !== void 0 ? _ajv$errors : undefined
  };
};