import type { Environment } from '@atlassian/jira-shared-types/src/tenant-context.tsx';

// This is the analytics channel that Jira AnalyticsListener is listening on.
export const ANALYTICS_CHANNEL = 'productAnalytics';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { useOnboardingState, useStopOnboarding } from './common/utils/use-onboarding-state';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export {
	useChangeboardingState,
	ENROLLED,
	NOT_ENROLLED,
} from './common/utils/use-changeboarding-state';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { isSyntheticTenant } from './common/utils/is-synthetic-tenant';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { toProformaEnvironment } from './common/utils/to-proforma-environment';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { toSupportedProjectType } from './common/utils/to-supported-project-type';

export const getUrls = (environment: Environment, cloudId: string) => ({
	api: `/gateway/api/proforma/cloudid/${cloudId}`,
	jira: '',
	templatesService:
		environment === 'prod'
			? 'https://proforma-templates.services.atlassian.com'
			: 'https://proforma-templates.stg.services.atlassian.com',
	analyticsService:
		environment === 'prod'
			? 'https://proforma-analytics.services.atlassian.com'
			: 'https://proforma-analytics.stg.services.atlassian.com',
});
