/* prettier-ignore */
/**
 * NOTE:
 *
 * This file is automatically generated by Traduki 2.0.
 * DO NOT CHANGE IT BY HAND or your changes will be lost.
 */
//Ukrainian (Ukraine)
export default {
  'proforma-jira-issue-forms.common.ui.footer.create-another-label': 'Створити ще одне завдання',
  'proforma-jira-issue-forms.common.ui.footer.create-button-label': 'Створити',
  'proforma-jira-issue-forms.common.ui.footer.failed-creating-flag-description': 'Повторіть спробу згодом.',
  'proforma-jira-issue-forms.common.ui.footer.failed-creating-flag-title': 'Не вдалося створити завдання.',
  'proforma-jira-issue-forms.common.ui.footer.failed-creating-with-save-errors-flag-description': 'Виправте неправильні відповіді й переконайтеся, що заповнено всі обов’язкові поля.',
  'proforma-jira-issue-forms.common.ui.footer.successfully-created-flag-copy-link-action': 'Копіювати посилання',
  'proforma-jira-issue-forms.common.ui.footer.successfully-created-flag-title': 'Ви створили завдання «{issueKey}».',
  'proforma-jira-issue-forms.common.ui.footer.successfully-created-flag-view-issue-action': 'Переглянути завдання',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-edit-form-link-label-for-project-admin': 'Редагувати форму',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-is-conditional-action-for-project-admin': 'Редагувати форму запиту',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-is-conditional-description-for-non-project-admin': 'Попросіть адміністратора перемістити поле <strong>{formFieldName}</strong> з розділу умови або скасуйте статус «обов’язкове» пов’язаного поля Jira <strong>{jiraFieldName}</strong> у формі запиту <strong>{typeName}</strong>.',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-is-conditional-description-for-project-admin': 'Перемістіть поле <strong>{formFieldName}</strong> з розділу умови або скасуйте статус «обов’язкове» пов’язаного поля Jira <strong>{jiraFieldName}</strong> у формі запиту <strong>{typeName}</strong>.',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-is-conditional-title': 'Поле «обов’язкове» в розділі умови',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-missing-description-for-non-project-admin': 'Попросіть адміністратора пов’язати поле в цій формі з полем Jira <strong>{jiraFieldName}</strong> у формі запиту <strong>{typeName}</strong>.',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-missing-description-for-project-admin': 'Пов’яжіть поле в цій формі з полем Jira<strong>{jiraFieldName}</strong> у формі запиту <strong>{typeName}</strong>.',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-missing-title': 'Поле «обов’язкове» не пов’язано',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-needs-to-be-mandatory-description-for-non-project-admin': 'Попросіть адміністратора додати для поля <strong>{formFieldName}</strong> цієї форми позначку «обов’язкове», щоб воно відповідало пов’язаному з ним полю Jira <strong>{jiraFieldName}</strong> у формі запиту <strong>{typeName}</strong>.',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-needs-to-be-mandatory-description-for-project-admin': 'Додайте для поля <strong>{formFieldName}</strong> із цієї форми позначку «обов’язкове», щоб воно відповідало пов’язаному з ним полю Jira <strong>{jiraFieldName}</strong> у формі запиту <strong>{typeName}</strong>.',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-needs-to-be-mandatory-title': 'Поле «необхідно» не збігається',
  'proforma-jira-issue-forms.common.utils.process-form-load-error.form-loading-failed-flag-description': 'Оновіть сторінку й повторіть спробу.',
  'proforma-jira-issue-forms.common.utils.process-form-load-error.form-loading-failed-flag-title': 'Не вдалося завантажити форму',
  'proforma-jira-issue-forms.controllers.form-store.object-search-error-description': 'Видаліть атрибути, які більше не існують у фільтрі конфігурації користувацького поля «Ресурси», і переконайтеся, що запит AQL дійсний.',
  'proforma-jira-issue-forms.controllers.form-store.object-search-error-title': 'Не вдалося завантажити об’єкти для цього поля',
  'proforma-jira-issue-forms.ui.direct.embed.successfully-created-button-embed': 'Створити ще одне завдання',
  'proforma-jira-issue-forms.ui.direct.embed.successfully-created-description-with-link-embed': 'Перегляньте код <a>issueKey</a>, щоб оновити це завдання або керувати ним.',
  'proforma-jira-issue-forms.ui.direct.embed.successfully-created-title-embed': 'Завдання створено',
  'proforma-jira-issue-forms.ui.general.direct-link-section-message-embed': 'Створюйте завдання за допомогою вбудованих форм, вставляючи це посилання на будь-якому сайті Atlassian. <a>Копіювати посилання</a>',
  'proforma-jira-issue-forms.ui.general.header.error-loading-project-associated-forms-description': 'Оновіть сторінку й повторіть спробу.',
  'proforma-jira-issue-forms.ui.general.header.error-loading-project-associated-forms-title': 'Не вдається завантажити форми',
  'proforma-jira-issue-forms.ui.general.header.error-loading-projects-description': 'Оновіть сторінку й повторіть спробу.',
  'proforma-jira-issue-forms.ui.general.header.error-loading-projects-title': 'Не вдається завантажити проєкти',
  'proforma-jira-issue-forms.ui.general.header.form-placeholder': 'Виберіть форму',
  'proforma-jira-issue-forms.ui.general.header.issue-type-placeholder': 'Виберіть тип завдання',
  'proforma-jira-issue-forms.ui.general.header.page-description-embed': 'Створюйте завдання за допомогою форм або прямих посилань на форми. <a>Докладніше про створення завдань за допомогою форм</a>',
  'proforma-jira-issue-forms.ui.general.header.page-header': 'Створення завдання',
  'proforma-jira-issue-forms.ui.general.header.project-has-no-associated-forms-jsm': 'Відсутні пов’язані з проєктом служби форми.',
  'proforma-jira-issue-forms.ui.general.header.project-has-no-associated-forms-jsw': 'Відсутні пов’язані з проєктом програмного забезпечення форми.',
  'proforma-jira-issue-forms.ui.general.header.project-has-no-associated-forms-jwm': 'Відсутні пов’язані з бізнес-проєктом форми.',
  'proforma-jira-issue-forms.ui.general.header.project-placeholder': 'Виберіть проєкт',
  'proforma-jira-issue-forms.ui.general.header.request-type-placeholder': 'Виберіть тип запиту'
};