/* prettier-ignore */
/**
 * NOTE:
 *
 * This file is automatically generated by Traduki 2.0.
 * DO NOT CHANGE IT BY HAND or your changes will be lost.
 */
//Vietnamese (Vietnam)
export default {
  'proforma-jira-issue-forms.common.ui.footer.create-another-label': 'Tạo vấn đề khác',
  'proforma-jira-issue-forms.common.ui.footer.create-button-label': 'Tạo',
  'proforma-jira-issue-forms.common.ui.footer.failed-creating-flag-description': 'Vui lòng thử lại sau.',
  'proforma-jira-issue-forms.common.ui.footer.failed-creating-flag-title': 'Chúng tôi không thể tạo vấn đề cho bạn',
  'proforma-jira-issue-forms.common.ui.footer.failed-creating-with-save-errors-flag-description': 'Giải quyết mọi câu trả lời không hợp lệ và kiểm tra để đảm bảo tất cả các trường bắt buộc đã hoàn tất.',
  'proforma-jira-issue-forms.common.ui.footer.successfully-created-flag-copy-link-action': 'Sao chép liên kết',
  'proforma-jira-issue-forms.common.ui.footer.successfully-created-flag-title': 'Bạn đã tạo vấn đề "{issueKey}".',
  'proforma-jira-issue-forms.common.ui.footer.successfully-created-flag-view-issue-action': 'Xem vấn đề',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-edit-form-link-label-for-project-admin': 'Sửa biểu mẫu',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-is-conditional-action-for-project-admin': 'Sửa biểu mẫu yêu cầu',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-is-conditional-description-for-non-project-admin': 'Yêu cầu quản trị viên của bạn di chuyển trường <strong>{formFieldName}</strong> ra khỏi mục có điều kiện hoặc xóa trạng thái &quot;bắt buộc&quot; khỏi trường Jira <strong>{jiraFieldName}</strong> đã liên kết trên biểu mẫu yêu cầu <strong>{typeName}</strong>.',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-is-conditional-description-for-project-admin': 'Di chuyển trường <strong>{formFieldName}</strong> ra khỏi mục có điều kiện hoặc xóa trạng thái &quot;bắt buộc&quot; khỏi trường Jira <strong>{jiraFieldName}</strong> đã liên kết trên biểu mẫu yêu cầu <strong>{typeName}</strong>.',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-is-conditional-title': 'Trường "bắt buộc" trong mục có điều kiện',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-missing-description-for-non-project-admin': 'Yêu cầu quản trị viên của bạn liên kết một trường trong biểu mẫu này với trường Jira <strong>{jiraFieldName}</strong> trong biểu mẫu yêu cầu <strong>{typeName}</strong>.',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-missing-description-for-project-admin': 'Liên kết một trường trong biểu mẫu này với trường Jira <strong>{jiraFieldName}</strong> trong biểu mẫu yêu cầu <strong>{typeName}</strong>.',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-missing-title': 'Trường "bắt buộc" không được liên kết',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-needs-to-be-mandatory-description-for-non-project-admin': 'Yêu cầu quản trị viên của bạn đánh dấu trường <strong>{formFieldName}</strong> trong biểu mẫu này là &quot;bắt buộc&quot; để khớp với trường Jira <strong>{jiraFieldName}</strong> đã liên kết trong biểu mẫu yêu cầu <strong>{typeName}</strong>.',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-needs-to-be-mandatory-description-for-project-admin': 'Đánh dấu trường <strong>{formFieldName}</strong> trong biểu mẫu này là &quot;bắt buộc&quot; để khớp với trường Jira <strong>{jiraFieldName}</strong> đã liên kết trong biểu mẫu yêu cầu <strong>{typeName}</strong>.',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-needs-to-be-mandatory-title': 'Trường "bắt buộc" không khớp',
  'proforma-jira-issue-forms.common.utils.process-form-load-error.form-loading-failed-flag-description': 'Làm mới trang rồi thử lại.',
  'proforma-jira-issue-forms.common.utils.process-form-load-error.form-loading-failed-flag-title': 'Chúng tôi không thể tải biểu mẫu của bạn',
  'proforma-jira-issue-forms.controllers.form-store.object-search-error-description': 'Xóa các thuộc tính không còn tồn tại trong bộ lọc cấu hình Trường tùy chỉnh Assets và đảm bảo truy vấn AQL hợp lệ.',
  'proforma-jira-issue-forms.controllers.form-store.object-search-error-title': 'Chúng tôi không tìm nạp được đối tượng nào cho trường này',
  'proforma-jira-issue-forms.ui.direct.embed.successfully-created-button-embed': 'Tạo vấn đề khác',
  'proforma-jira-issue-forms.ui.direct.embed.successfully-created-description-with-link-embed': 'Xem <a>issueKey</a> để cập nhật hoặc quản lý vấn đề này.',
  'proforma-jira-issue-forms.ui.direct.embed.successfully-created-title-embed': 'Chúng tôi đã tạo vấn đề của bạn',
  'proforma-jira-issue-forms.ui.general.direct-link-section-message-embed': 'Tạo vấn đề dùng biểu mẫu nhúng bằng cách dán liên kết này vào bất kỳ trang Atlassian nào. <a>Sao chép liên kết</a>',
  'proforma-jira-issue-forms.ui.general.header.error-loading-project-associated-forms-description': 'Làm mới trang rồi thử lại.',
  'proforma-jira-issue-forms.ui.general.header.error-loading-project-associated-forms-title': 'Chúng tôi gặp vấn đề khi tải biểu mẫu của bạn',
  'proforma-jira-issue-forms.ui.general.header.error-loading-projects-description': 'Làm mới trang rồi thử lại.',
  'proforma-jira-issue-forms.ui.general.header.error-loading-projects-title': 'Chúng tôi gặp vấn đề khi tải dự án của bạn',
  'proforma-jira-issue-forms.ui.general.header.form-placeholder': 'Chọn biểu mẫu',
  'proforma-jira-issue-forms.ui.general.header.issue-type-placeholder': 'Chọn loại vấn đề',
  'proforma-jira-issue-forms.ui.general.header.page-description-embed': 'Tạo vấn đề bằng biểu mẫu, hoặc tạo liên kết trực tiếp đến biểu mẫu. <a>Tìm hiểu thêm về việc sử dụng biểu mẫu để tạo vấn đề.</a>',
  'proforma-jira-issue-forms.ui.general.header.page-header': 'Tạo vấn đề',
  'proforma-jira-issue-forms.ui.general.header.project-has-no-associated-forms-jsm': 'Dự án dịch vụ này không có bất kỳ biểu mẫu liên kết nào.',
  'proforma-jira-issue-forms.ui.general.header.project-has-no-associated-forms-jsw': 'Dự án phần mềm này không có bất kỳ biểu mẫu liên kết nào.',
  'proforma-jira-issue-forms.ui.general.header.project-has-no-associated-forms-jwm': 'Dự án kinh doanh này không có bất kỳ biểu mẫu liên kết nào.',
  'proforma-jira-issue-forms.ui.general.header.project-placeholder': 'Chọn dự án',
  'proforma-jira-issue-forms.ui.general.header.request-type-placeholder': 'Chọn loại yêu cầu'
};