// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from '@emotion/styled';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const IssueFormsBody = styled.div({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  fontSize: '14px',
  maxWidth: '800px',
  minWidth: '400px',
  overflow: 'visible',
  paddingBottom: "var(--ds-space-250, 20px)"
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const IssueFormsBodyWrapper = styled.div({
  boxSizing: 'border-box',
  display: 'flex',
  flex: 1,
  justifyContent: 'center',
  scrollbarGutter: 'stable both-edges',
  overflowY: 'auto'
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const IssueFormsContent = styled.div({
  backgroundColor: "var(--ds-surface, white)",
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  width: '100%'
});