/* prettier-ignore */
/**
 * NOTE:
 *
 * This file is automatically generated by Traduki 2.0.
 * DO NOT CHANGE IT BY HAND or your changes will be lost.
 */
//
export default {
  'proforma-jira-issue-forms.common.ui.footer.create-another-label': '⁣⁢Create another issue‌‍‍⁡⁠⁡⁫⁫⁠‌‌‌⁬⁬⁮⁯⁠⁭‌‍⁠‍⁮⁣⁤',
  'proforma-jira-issue-forms.common.ui.footer.create-button-label': '⁣⁢Create⁯⁯⁡⁪؜⁬‌⁮⁠؜⁡⁡⁯⁫‍⁮⁠⁮⁯⁮⁯⁪⁣⁤',
  'proforma-jira-issue-forms.common.ui.footer.failed-creating-flag-description': '⁣⁢Please try again later.⁭‍⁫⁮؜⁭⁪‍⁫‌؜؜؜⁬⁡⁪‌⁠‌؜⁠⁣⁤',
  'proforma-jira-issue-forms.common.ui.footer.failed-creating-flag-title': '⁣⁢We couldn’t create your issue.؜‌‍⁡⁡⁪⁫⁫⁭‌⁫⁡⁯‍؜⁬‍⁭؜⁭⁠⁣⁤',
  'proforma-jira-issue-forms.common.ui.footer.failed-creating-with-save-errors-flag-description': '⁣⁢Resolve any invalid answers and check all required fields are complete.⁫⁪‌⁠⁮⁬⁠‍⁯⁪‌⁡‍⁫؜؜⁡⁯⁯⁫⁣⁤',
  'proforma-jira-issue-forms.common.ui.footer.successfully-created-flag-copy-link-action': '⁣⁢Copy link⁫⁫⁬⁡⁡⁬⁯⁫؜⁫⁬⁪⁠⁮‌⁮⁪⁡⁠‍⁮؜⁭‍⁮⁮⁬⁣⁤',
  'proforma-jira-issue-forms.common.ui.footer.successfully-created-flag-title': '⁣⁢You’ve created "{issueKey}" issue.⁭⁬⁬⁬‍⁯⁭⁪⁠؜⁪⁮‌⁫⁯‌⁭؜⁭⁫⁮⁬⁣⁤',
  'proforma-jira-issue-forms.common.ui.footer.successfully-created-flag-view-issue-action': '⁣⁢View issue⁯⁯⁬⁠⁯⁪‌⁠‍‌⁬⁮⁠⁬⁪⁠‌⁮⁮⁡⁠⁠⁣⁤',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-edit-form-link-label-for-project-admin': '⁣⁢Edit form‌‍⁡⁯⁫⁭⁪‌؜⁡⁯⁮⁯⁮⁬⁯⁪⁮⁭‌؜⁡⁬⁣⁤',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-is-conditional-action-for-project-admin': '⁣⁢Edit request form⁠⁪⁡⁬؜‍‌⁮‌⁫؜‌⁠⁭⁭⁬⁡⁣⁤',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-is-conditional-description-for-non-project-admin': '⁣⁢Ask your admin to move the <strong>{formFieldName}</strong> field out of the conditional section, or remove the ‘required’ status from its linked <strong>{jiraFieldName}</strong> Jira field on the <strong>{typeName}</strong> request form.⁯⁡⁬⁪⁫⁯⁬⁫⁭⁮‌⁠؜⁫⁠⁪⁮⁪⁯‌‌⁪⁬⁪⁣⁤',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-is-conditional-description-for-project-admin': '⁣⁢Move the <strong>{formFieldName}</strong> field out of the conditional section, or remove the ‘required’ status from its linked <strong>{jiraFieldName}</strong> Jira field on the <strong>{typeName}</strong> request form.⁭⁠؜⁪⁯‌⁬⁯⁠⁭⁪⁭⁯⁯⁫⁠⁫⁬⁡⁮⁭⁣⁤',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-is-conditional-title': '⁣⁢‘Required’ field in conditional section⁪‍⁬⁯⁠⁫⁫⁯⁠⁭‍⁮⁫⁡⁠⁪⁡⁮⁫؜؜⁣⁤',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-missing-description-for-non-project-admin': '⁣⁢Ask your admin to link a field in this form with the <strong>{jiraFieldName}</strong> Jira field in the <strong>{typeName}</strong> request form.⁡⁬⁡‌⁪⁡⁮⁫⁠⁪⁯⁬‌⁪⁯؜⁭⁫⁬⁪؜⁠⁣⁤',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-missing-description-for-project-admin': '⁣⁢Link a field in this form with the <strong>{jiraFieldName}</strong> Jira field in the <strong>{typeName}</strong> request form.؜⁭‌⁮‌⁯⁮‌⁭‌⁪⁠⁪⁭‌⁣⁤',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-missing-title': '⁣⁢‘Required’ field is not linked⁠؜‍⁡⁮⁮⁫⁯⁬⁮⁪‌⁫⁠⁯‌⁭⁯⁬⁡⁬‍⁮‌⁪⁡؜⁣⁤',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-needs-to-be-mandatory-description-for-non-project-admin': '⁣⁢Ask your admin to mark the <strong>{formFieldName}</strong> field in this form as ‘required’, to match its linked <strong>{jiraFieldName}</strong> Jira field in the <strong>{typeName}</strong> request form.⁯⁮‍⁬⁫؜‍‌⁮⁠⁭‌‌‌⁠⁡⁬⁠⁣⁤',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-needs-to-be-mandatory-description-for-project-admin': '⁣⁢Mark the <strong>{formFieldName}</strong> field in this form as ‘required’, to match its linked <strong>{jiraFieldName}</strong> Jira field in the <strong>{typeName}</strong> request form.⁡⁫⁡⁮⁠⁭‌⁭⁭⁡⁯⁠⁠⁯‍⁬⁯⁬⁡⁠⁭⁣⁤',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-needs-to-be-mandatory-title': '⁣⁢‘Required’ field does not match⁠⁪⁠‌‍⁬‌‍⁠⁯⁮⁬⁬⁡⁡⁯‌⁬⁠⁬⁭⁣⁤',
  'proforma-jira-issue-forms.common.utils.process-form-load-error.form-loading-failed-flag-description': '⁣⁢Refresh the page and try again.⁠⁯⁮⁭‍⁯⁫⁮⁭‍؜⁪؜⁭‌؜؜⁯⁮⁮⁪‍⁫⁣⁤',
  'proforma-jira-issue-forms.common.utils.process-form-load-error.form-loading-failed-flag-title': '⁣⁢We couldn’t load your form‍⁡‍⁭‍⁠⁮‌⁬‍⁭‌؜⁠‌⁭⁡⁮‍⁬⁫⁡⁣⁤',
  'proforma-jira-issue-forms.controllers.form-store.object-search-error-description': '⁣⁢Remove attributes that no longer exist in the Assets Custom field configuration filter, and ensure the AQL query is valid.⁭⁭⁫‍‌⁮‍⁡⁡⁡⁯⁫⁫‍⁪⁬⁡⁡⁯⁭‍⁡⁪⁫⁣⁤',
  'proforma-jira-issue-forms.controllers.form-store.object-search-error-title': "⁣⁢We couldn't fetch any objects for this field؜⁫؜‍⁠⁪⁠⁬⁮⁬⁭⁡⁪⁯⁪⁭⁯⁪⁪⁫⁮⁡⁠⁣⁤",
  'proforma-jira-issue-forms.ui.direct.embed.successfully-created-button-embed': '⁣⁢Create another issue⁡⁠⁡⁯⁪⁯⁬⁬⁬⁪⁡⁫⁡‌؜⁯⁠⁣⁤',
  'proforma-jira-issue-forms.ui.direct.embed.successfully-created-description-with-link-embed': '⁣⁢View <a>issueKey</a> to update, or manage this issue.⁬⁪⁫⁯⁯⁫⁯⁫⁮⁠⁮⁬⁡⁠⁡⁭⁯؜⁡⁫⁣⁤',
  'proforma-jira-issue-forms.ui.direct.embed.successfully-created-title-embed': '⁣⁢We’ve created your issue⁠⁠؜⁪⁪⁪⁫‍⁠⁡⁮⁫⁯‌⁠⁬⁮⁯⁭‌⁪⁡⁣⁤',
  'proforma-jira-issue-forms.ui.general.direct-link-section-message-embed': '⁣⁢Create issues using embedded forms by pasting this link on any Atlassian site. <a>Copy link</a>⁭⁠‌⁭⁯⁮⁠⁪‌؜⁯؜⁭⁮⁯⁬⁡⁪⁬⁪⁫⁣⁤',
  'proforma-jira-issue-forms.ui.general.header.error-loading-project-associated-forms-description': '⁣⁢Refresh the page and try again.؜⁫⁠⁮⁡⁠⁮‌⁠⁬‌⁪⁠⁬⁬⁠؜؜⁮‍‍؜‌⁭⁯⁣⁤',
  'proforma-jira-issue-forms.ui.general.header.error-loading-project-associated-forms-title': '⁣⁢We’re having trouble loading your forms‍⁮⁬⁬⁫؜⁪⁪‍‍‍؜‍‌⁡⁡⁫⁡⁪⁭⁮⁣⁤',
  'proforma-jira-issue-forms.ui.general.header.error-loading-projects-description': '⁣⁢Refresh the page and try again.‌‌‌⁠⁡⁭⁪⁡⁪⁭⁡‌⁬⁪⁫⁪⁮‌⁮⁠‌⁠⁡⁮⁣⁤',
  'proforma-jira-issue-forms.ui.general.header.error-loading-projects-title': '⁣⁢We’re having trouble loading your projects⁬⁪⁪⁪⁫⁪⁫⁡⁮⁠⁪‍⁮⁬⁡⁠؜⁫⁫⁭⁡⁭⁫⁫⁠⁬⁣⁤',
  'proforma-jira-issue-forms.ui.general.header.form-placeholder': '⁣⁢Select a form⁯⁪⁭‌⁫⁭⁯⁯⁯⁯⁪⁫‍⁮⁬⁭⁬⁡⁭⁯⁣⁤',
  'proforma-jira-issue-forms.ui.general.header.issue-type-placeholder': '⁣⁢Select an issue type‌⁡؜⁫⁮⁠⁮⁭؜⁯⁡⁯⁫‌⁡⁠⁫⁠⁬⁠⁣⁤',
  'proforma-jira-issue-forms.ui.general.header.page-description-embed': '⁣⁢Create issues using a form, or from a link to a form directly. <a>Learn more about using forms to create issues.</a>‍⁫⁯⁠⁫‍‌؜؜⁭⁬⁪⁬⁫⁯⁯⁫⁭⁯⁠⁮⁭⁣⁤',
  'proforma-jira-issue-forms.ui.general.header.page-header': '⁣⁢Create issue⁮⁭؜⁡⁫⁭⁠⁠‍⁠⁯⁫⁯⁡⁡⁫⁡‌⁡⁠⁭‍⁫⁣⁤',
  'proforma-jira-issue-forms.ui.general.header.project-has-no-associated-forms-jsm': '⁣⁢This service project doesn’t have any associated forms.‌⁫⁠⁪‌⁮⁯⁪⁬⁫⁠‌‌⁮⁠⁮⁯⁬‍⁭⁡‍⁫⁯⁣⁤',
  'proforma-jira-issue-forms.ui.general.header.project-has-no-associated-forms-jsw': '⁣⁢This software project doesn’t have any associated forms.‍⁫؜⁯⁯⁯؜⁬‌‍⁮⁬؜⁫‌‍⁮؜⁯⁯⁪⁪⁪⁯؜⁭⁣⁤',
  'proforma-jira-issue-forms.ui.general.header.project-has-no-associated-forms-jwm': '⁣⁢This business project doesn’t have any associated forms.⁬⁡⁠‌⁫⁭‍⁭⁡⁮‍⁪⁫⁪‌‌⁡⁠⁫؜⁭⁣⁤',
  'proforma-jira-issue-forms.ui.general.header.project-placeholder': '⁣⁢Select a project‌⁮⁬⁡⁡⁮⁠⁠⁯⁬⁯⁪⁠⁬⁭⁮⁯‌⁮‍‌‍⁣⁤',
  'proforma-jira-issue-forms.ui.general.header.request-type-placeholder': '⁣⁢Select a request type⁠‌⁪؜⁫⁡‍‌⁡⁫‌⁬‌‌⁠⁫⁬⁪؜⁮⁮⁫⁪⁡⁣⁤'
};