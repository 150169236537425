import { ApiErrorType } from './ApiErrorType';
import { ErrorContextOf } from './ErrorContext';
export function constructApiError(error, status, responseJson, requestName, context, contextId, details, isClientFetchError, traceId) {
  switch (error) {
    case ApiErrorType.client:
      return {
        error: ApiErrorType.client,
        isClientFetchError,
        traceId,
        status,
        requestName
      };
    case ApiErrorType.clientWithDetails:
      return {
        error: ApiErrorType.clientWithDetails,
        context: ErrorContextOf(context),
        details: details || '',
        isClientFetchError,
        traceId,
        status,
        requestName
      };
    case ApiErrorType.json:
      return {
        error: ApiErrorType.json,
        details: details || '',
        isClientFetchError,
        traceId,
        status,
        requestName
      };
    case ApiErrorType.jwt:
      return {
        error: ApiErrorType.jwt,
        details: details || '',
        isClientFetchError,
        traceId,
        status,
        requestName
      };
    default:
      return {
        error: ApiErrorType.unknown,
        message: error,
        status: status,
        responseJson,
        isClientFetchError,
        traceId,
        requestName
      };
  }
}
export const isUnknownApiError = apiError => apiError.error === ApiErrorType.unknown;
export const getFormSaveErrors = apiError => {
  if (isUnknownApiError(apiError) && apiError.status === 400) {
    return {
      generalErrors: [],
      errorsByQuestionId: {},
      ...apiError.responseJson
    };
  }
  return undefined;
};