/* prettier-ignore */
/**
 * NOTE:
 *
 * This file is automatically generated by Traduki 2.0.
 * DO NOT CHANGE IT BY HAND or your changes will be lost.
 */
//Portuguese (Brazil)
export default {
  'proforma-jira-issue-forms.common.ui.footer.create-another-label': 'Criar outro item',
  'proforma-jira-issue-forms.common.ui.footer.create-button-label': 'Criar',
  'proforma-jira-issue-forms.common.ui.footer.failed-creating-flag-description': 'Tente de novo mais tarde.',
  'proforma-jira-issue-forms.common.ui.footer.failed-creating-flag-title': 'Não foi possível criar o item.',
  'proforma-jira-issue-forms.common.ui.footer.failed-creating-with-save-errors-flag-description': 'Resolva todas as respostas inválidas e verifique se todos os campos obrigatórios estão preenchidos.',
  'proforma-jira-issue-forms.common.ui.footer.successfully-created-flag-copy-link-action': 'Copiar link',
  'proforma-jira-issue-forms.common.ui.footer.successfully-created-flag-title': "Você criou o item ''{issueKey}'.",
  'proforma-jira-issue-forms.common.ui.footer.successfully-created-flag-view-issue-action': 'Visualizar item',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-edit-form-link-label-for-project-admin': 'Editar formulário',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-is-conditional-action-for-project-admin': 'Editar formulário de solicitação',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-is-conditional-description-for-non-project-admin': 'Peça ao administrador para mover o campo <strong>{formFieldName}</strong> para fora da seção condicional ou remover o status &quot;obrigatório&quot; do campo vinculado do Jira <strong>{jiraFieldName}</strong> no formulário de solicitação <strong>{typeName}</strong>.',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-is-conditional-description-for-project-admin': 'Mova o campo <strong>{formFieldName}</strong> para fora da seção condicional ou remova o status &quot;obrigatório&quot; do campo <strong>{jiraFieldName}</strong> vinculado do Jira no formulário de solicitação <strong>{typeName}</strong>.',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-is-conditional-title': 'Campo "obrigatório" na seção condicional',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-missing-description-for-non-project-admin': 'Peça ao administrador para vincular um campo neste formulário ao campo <strong>{jiraFieldName}</strong> do Jira no formulário de solicitação <strong>{typeName}</strong>.',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-missing-description-for-project-admin': 'Vincule um campo neste formulário ao campo do Jira <strong>{jiraFieldName}</strong> no formulário de solicitação <strong>{typeName}</strong>.',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-missing-title': 'O campo "obrigatório" não está vinculado',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-needs-to-be-mandatory-description-for-non-project-admin': 'Peça ao administrador para marcar o campo <strong>{formFieldName}</strong> neste formulário como &quot;obrigatório&quot;, para corresponder ao campo vinculado <strong>{jiraFieldName}</strong> do Jira no formulário de solicitação <strong>{typeName}</strong>.',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-needs-to-be-mandatory-description-for-project-admin': 'Marque o campo <strong>{formFieldName}</strong> neste formulário como &quot;obrigatório&quot; para corresponder ao campo <strong>{jiraFieldName}</strong> vinculado do Jira vinculado no formulário de solicitação <strong>{typeName}</strong>.',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-needs-to-be-mandatory-title': 'O campo "obrigatório" não corresponde',
  'proforma-jira-issue-forms.common.utils.process-form-load-error.form-loading-failed-flag-description': 'Atualize a página e tente de novo.',
  'proforma-jira-issue-forms.common.utils.process-form-load-error.form-loading-failed-flag-title': 'Não foi possível carregar o formulário',
  'proforma-jira-issue-forms.controllers.form-store.object-search-error-description': 'Remova os atributos que não existem mais no filtro de configuração do campo personalizado Recursos e verifique se a consulta AQL é válida.',
  'proforma-jira-issue-forms.controllers.form-store.object-search-error-title': 'Não foi possível buscar objetos para este campo',
  'proforma-jira-issue-forms.ui.direct.embed.successfully-created-button-embed': 'Criar outro item',
  'proforma-jira-issue-forms.ui.direct.embed.successfully-created-description-with-link-embed': 'Visualize <a>IssueKey</a> para atualizar ou gerenciar este item.',
  'proforma-jira-issue-forms.ui.direct.embed.successfully-created-title-embed': 'O item foi criado',
  'proforma-jira-issue-forms.ui.general.direct-link-section-message-embed': 'Crie itens usando formulários incorporados colando esse link em qualquer site da Atlassian. <a>Copiar link</a>',
  'proforma-jira-issue-forms.ui.general.header.error-loading-project-associated-forms-description': 'Atualize a página e tente de novo.',
  'proforma-jira-issue-forms.ui.general.header.error-loading-project-associated-forms-title': 'Há alguns problemas para carregar os formulários',
  'proforma-jira-issue-forms.ui.general.header.error-loading-projects-description': 'Atualize a página e tente de novo.',
  'proforma-jira-issue-forms.ui.general.header.error-loading-projects-title': 'Há alguns problemas para carregar os projetos',
  'proforma-jira-issue-forms.ui.general.header.form-placeholder': 'Selecionar o formulário',
  'proforma-jira-issue-forms.ui.general.header.issue-type-placeholder': 'Selecionar o tipo do item',
  'proforma-jira-issue-forms.ui.general.header.page-description-embed': 'Crie itens usando um formulário ou por meio de um link direto para um formulário. <a>Saiba mais sobre como usar formulários para criar itens.</a>',
  'proforma-jira-issue-forms.ui.general.header.page-header': 'Criar item',
  'proforma-jira-issue-forms.ui.general.header.project-has-no-associated-forms-jsm': 'Este projeto de serviço não tem formulários associados.',
  'proforma-jira-issue-forms.ui.general.header.project-has-no-associated-forms-jsw': 'Este projeto de software não tem formulários associados.',
  'proforma-jira-issue-forms.ui.general.header.project-has-no-associated-forms-jwm': 'Este projeto de negócios não tem formulários associados.',
  'proforma-jira-issue-forms.ui.general.header.project-placeholder': 'Selecionar um projeto',
  'proforma-jira-issue-forms.ui.general.header.request-type-placeholder': 'Selecionar o tipo de solicitação'
};