import { createContainer, createHook, createStore } from 'react-sweet-state';
const actions = {
  load: (jiraField, query) => ({
    getState,
    setState
  }, {
    apiUtil
  }) => {
    if (!getState().loading) {
      setState({
        data: undefined,
        error: undefined,
        loading: true
      });
      let uri = '';
      const isCustomLabelsField = jiraField.startsWith('customfield_');
      if (isCustomLabelsField) {
        const customFieldId = jiraField === null || jiraField === void 0 ? void 0 : jiraField.split('_')[1];
        uri = `/rest/api/1.0/labels/suggest?customFieldId=${customFieldId}&query=${query !== null && query !== void 0 ? query : ''}`;
      } else {
        uri = `/rest/api/1.0/labels/suggest?query=${query !== null && query !== void 0 ? query : ''}`;
      }
      apiUtil.jiraGet(uri).then(data => data.suggestions.map(jiraLabel => ({
        value: jiraLabel.label,
        label: jiraLabel.label
      }))).then(jiraLabels => {
        setState({
          data: jiraLabels,
          loading: false
        });
      }).catch(apiError => {
        setState({
          error: apiError,
          loading: false
        });
      });
    }
  }
};
const Store = createStore({
  initialState: {
    data: undefined,
    error: undefined,
    loading: false
  },
  actions,
  name: 'LabelChoicesService'
});
export const LabelChoicesServiceContainer = createContainer(Store);
export const useLabelChoicesService = createHook(Store);