import { createContainer, createHook, createStore } from 'react-sweet-state';
const actions = {
  load: (formDetails, request) => ({
    getState,
    setState
  }, {
    apiUtil
  }) => {
    if (!getState().loading) {
      setState({
        data: undefined,
        error: undefined,
        loading: true
      });
      apiUtil.post(`/api/cloud/createissue/project/${formDetails.projectId}/issuetype/${formDetails.issueType.id}/form/${formDetails.projectFormId}/fielddata`, request).then(response => {
        setState({
          data: response,
          loading: false
        });
      }).catch(apiError => {
        setState({
          error: apiError,
          loading: false
        });
      });
    }
  }
};
const Store = createStore({
  initialState: {
    data: undefined,
    error: undefined,
    loading: false
  },
  actions,
  name: 'FieldDataService'
});
export const FieldDataServiceContainer = createContainer(Store);
export const useFieldDataService = createHook(Store);